import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftNavbarComponent } from './left-navbar/left-navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AccountLanguagesDialogComponent } from './account-languages-dialog/account-languages-dialog.component';
import { PassTemplatesComponent } from './pass-templates/pass-templates.component';
import { TridysComponent } from './tridys/tridys.component';
import { MediaComponent } from './media/media.component';
import { ProductsComponent } from './products/products.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { TaskTemplatesComponent } from './task-templates/task-templates.component';
import { FinishedTasksComponent } from './finished-tasks/finished-tasks.component';
import { UsersComponent } from './users/users.component';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InjectComponentDirective } from './inject-component.directive';
import { BillboardImagePhoneComponent } from './widgets/billboard-image-phone/billboard-image-phone.component';
import { ImagesHorizontalPhoneComponent } from './widgets/images-horizontal-phone/images-horizontal-phone.component';
import { TextPhoneComponent } from './widgets/text-phone/text-phone.component';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { ImagePhoneComponent } from './widgets/image-phone/image-phone.component';
import { BillboardVideoPhoneComponent } from './widgets/billboard-video-phone/billboard-video-phone.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VideoPhoneComponent } from './widgets/video-phone/video-phone.component';
import { AnnotatedMapPhoneComponent } from './widgets/annotated-map-phone/annotated-map-phone.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglemapComponent } from './googlemap/googlemap.component';
import { MapMarkerComponent } from './map-marker/map-marker.component';
import { ImagesHorizontalEditComponent } from './widgets/editWidgets/images-horizontal-edit/images-horizontal-edit.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs'
import { MatDividerModule } from '@angular/material/divider'
import { MAT_DATE_LOCALE, MatOption, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { BillboardImageEditComponent } from './widgets/editWidgets/billboard-image-edit/billboard-image-edit.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextEditComponent } from './widgets/editWidgets/text-edit/text-edit.component';
import { ImageEditComponent } from './widgets/editWidgets/image-edit/image-edit.component';
import { BillboardVideoEditComponent } from './widgets/editWidgets/billboard-video-edit/billboard-video-edit.component';
import { VideoEditComponent } from './widgets/editWidgets/video-edit/video-edit.component';
import { AnnotatedMapEditComponent } from './widgets/editWidgets/annotated-map-edit/annotated-map-edit.component';
import { MapMarkerEditComponent } from './map-marker-edit/map-marker-edit.component';
import { CallToActionEditComponent } from './widgets/editWidgets/call-to-action-edit/call-to-action-edit.component';
import { CallToActionPhoneComponent } from './widgets/call-to-action-phone/call-to-action-phone.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from  '@angular/material/slide-toggle';
import { Model3dTestComponent } from './widgets/model3d-Test/model3d-test.component'
import { Model3dPhoneComponent } from './widgets/model3d-phone/model3d-phone.component';
import { Model3dViewComponent } from './widgets/model3d-view/model3d-view.component';
import { Model3dEditComponent } from './widgets/editWidgets/model3d-edit/model3d-edit.component';
import { ImageBlocksPhoneComponent } from './widgets/image-blocks-phone/image-blocks-phone.component';
import { ImageBlocksEditComponent } from './widgets/editWidgets/image-blocks-edit/image-blocks-edit.component';
import { PassTemplatesThemeEditComponent } from './pass-templates-theme-edit/pass-templates-theme-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxColorsModule } from 'ngx-colors';
import { getApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { browserPopupRedirectResolver, browserSessionPersistence, connectAuthEmulator, indexedDBLocalPersistence, initializeAuth, provideAuth,getAuth } from '@angular/fire/auth';
import { initializeFirestore, connectFirestoreEmulator, enableIndexedDbPersistence, provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideStorage, getStorage, connectStorageEmulator } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire/compat';
/* import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideStorage, getStorage, connectStorageEmulator } from '@angular/fire/storage';
import { AngularFireModule} from '@angular/fire/compat';
>>>>>>> development */
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogInComponent } from './log-in/log-in.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { ContentTypesListComponent } from './content-types-list/content-types-list.component';
import { CreateContentTypeDialogComponent } from './create-content-type-dialog/create-content-type-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductRegistrationPhoneComponent } from './widgets/product-registration-phone/product-registration-phone.component';
import { ProductRegistrationEditComponent } from './widgets/editWidgets/product-registration-edit/product-registration-edit.component';
import { ProductsPhoneComponent } from './widgets/products-phone/products-phone.component';
import { ProductsEditComponent } from './widgets/editWidgets/products-edit/products-edit.component';
import { ShopifyPhoneComponent } from './widgets/shopify-phone/shopify-phone.component';
import { InstagramPhoneComponent } from './widgets/instagram-phone/instagram-phone.component';
import { ContentComponent } from './content/content.component';
import { ContentEditDialogComponent } from './content-edit-dialog/content-edit-dialog.component';
import { EditValueContentDialogComponent } from './edit-value-content-dialog/edit-value-content-dialog.component';
import { MediaFolderComponent } from './media-folder/media-folder.component';
import { FinishedTasksItemComponent } from './finished-tasks-item/finished-tasks-item.component';
import { FinishedTasksMapComponent } from './finished-tasks-map/finished-tasks-map.component';
import { TaskTemplateItemComponent } from './task-template-item/task-template-item.component';
import { TaskTemplateEditSectionDialogComponent } from './task-template-edit-section-dialog/task-template-edit-section-dialog.component';
import { WorkTaskTemplateDialogComponent } from './work-task-template-dialog/work-task-template-dialog.component';
import { WorkTaskTemplateEditValueDialogComponent } from './work-task-template-edit-value-dialog/work-task-template-edit-value-dialog.component';
import { TaskTemplateFieldDialogComponent } from './task-template-field-dialog/task-template-field-dialog.component';
import { TridysItemComponent } from './tridys-item/tridys-item.component';
import { TridysCreateDialogComponent } from './tridys-create-dialog/tridys-create-dialog.component';
import { ProductsItemComponent } from './products-item/products-item.component';
import { ContextIdSelectorComponent } from './context-id-selector/context-id-selector.component';
import { SplashVideoPhoneComponent } from './widgets/splash-video-phone/splash-video-phone.component';
import { SplashVideoEditComponent } from './widgets/editWidgets/splash-video-edit/splash-video-edit.component';
import { AdvancedExperiencesComponent } from './advanced-experiences/advanced-experiences.component';
import { AdvancedExperiencesItemComponent } from './advanced-experiences-item/advanced-experiences-item.component';
import { ImagesPhoneComponent } from './widgets/images-phone/images-phone.component';
import { ImagesEditComponent } from './widgets/editWidgets/images-edit/images-edit.component';
import { MediaDetailsDialogComponent } from './widgets/editWidgets/media-details-dialog/media-details-dialog.component';
import { FilterContentsPipe} from './shared/filter.pipe';
import { FilterTridyPipe } from './shared/filter-tridy.pipe';
import { FilterExperiencesPipe } from './shared/filterExperiences.pipe';
import { FilterProductsPipe } from './shared/filter-product.pipe';
import { FilterTasksPipe } from './shared/filter-tasks.pipe';
import { FilterFinishedPipe } from './shared/filter-finished.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { DeleteContentTypesDialogComponent } from './delete-content-types-dialog/delete-content-types-dialog.component';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { DatePipe, CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { QrCodeDialogComponent } from './qr-code-dialog/qr-code-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragAndDropDirective } from './directive/drag-and-drop.directive';
import { AdvancedExperiencesDialogComponent } from './advanced-experiences-dialog/advanced-experiences-dialog.component';
import { LanguageEditorDialogComponent } from './language-editor-dialog/language-editor-dialog.component';
import { LanguageVisualisationDialogComponent } from './language-visualisation-dialog/language-visualisation-dialog.component';

import { AngularFireDatabaseModule, USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule, USE_DEVICE_LANGUAGE, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule, REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { InfoMesageDialogComponent } from './info-mesage-dialog/info-mesage-dialog.component';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { ReportsItemTridysListComponent } from './reports-item-tridys-list/reports-item-tridys-list.component';
import { ReportCreateDialogComponent } from './report-create-dialog/report-create-dialog.component';
import { ReportAddFilterFieldDialogComponent } from './report-add-filter-field-dialog/report-add-filter-field-dialog.component';
import { DuplicateReportTemplateDialogComponent } from './duplicate-report-template-dialog/duplicate-report-template-dialog.component';
import { InterfacesListComponent } from './interfaces-list/interfaces-list.component';
import { InterfacesDataDialogComponent } from './interfaces-data-dialog/interfaces-data-dialog.component';
import { InterfacesDataMappingDialogComponent } from './interfaces-data-mapping-dialog/interfaces-data-mapping-dialog.component';
import { TaskTemplateFieldNewOptionDialogComponent } from './task-template-field-new-option-dialog/task-template-field-new-option-dialog.component';
import { AnnotatedMapAnnotationDialogComponent } from './widgets/annotated-map-annotation-dialog/annotated-map-annotation-dialog.component';
import { TaskTemplatesGroupsComponent } from './task-templates-groups/task-templates-groups.component';
import { TaskTemplatesGroupsDialogComponent } from './task-templates-groups-dialog/task-templates-groups-dialog.component';
import { ContentTypesSchemaComponent } from './content-types-schema/content-types-schema.component';
import { ContentTypesSchemaPropertiesDialogComponent } from './content-types-schema-properties-dialog/content-types-schema-properties-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTreeNestedDataSource, MatTreeModule} from '@angular/material/tree';
import { MatTooltipModule} from '@angular/material/tooltip';
import { RegisterComponent } from './register/register.component';
import { RegisterConfirmEmailDialogComponent } from './register-confirm-email-dialog/register-confirm-email-dialog.component';
import { RegisterSetPasswordComponent } from './register-set-password/register-set-password.component';
import { RegisterSetPasswordDialogComponent } from './register-set-password-dialog/register-set-password-dialog.component';
import { ContentTypesViewDialogComponent } from './content-types-view-dialog/content-types-view-dialog.component';
import { ContentTypesSchemaViewDialogComponent } from './content-types-schema-view-dialog/content-types-schema-view-dialog.component';
import { AdvancedExperienceViewDialogComponent } from './advanced-experience-view-dialog/advanced-experience-view-dialog.component';
import { CancelChangesDialogComponent } from './cancel-changes-dialog/cancel-changes-dialog.component';
import { TaskTemplateCreateDialogComponent } from './task-template-create-dialog/task-template-create-dialog.component';
import { WorkTaskTemplateAdvancedDialogComponent } from './work-task-template-advanced-dialog/work-task-template-advanced-dialog.component';
import { SpacerPhoneComponent } from './widgets/spacer-phone/spacer-phone.component';
import { ButtonsPhoneComponent } from './widgets/buttons-phone/buttons-phone.component';
import { SpacerEditComponent } from './widgets/editWidgets/spacer-edit/spacer-edit.component';
import { ButtonsEditComponent } from './widgets/editWidgets/buttons-edit/buttons-edit.component';
import { UpdateConfirmationDialogComponent } from './update-confirmation-dialog/update-confirmation-dialog.component';
import { ListEditComponent } from './widgets/editWidgets/list-edit/list-edit.component';
import { ListPhoneComponent } from './widgets/list-phone/list-phone.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MapMarkerHorizontalComponent } from './map-marker-horizontal/map-marker-horizontal.component';
import { FormPhoneComponent } from './widgets/form-phone/form-phone.component';
import { FormEditComponent } from './widgets/editWidgets/form-edit/form-edit.component';
import { FormEditFieldDialogComponent } from './widgets/editWidgets/form-edit-field-dialog/form-edit-field-dialog.component';
import { FilterUIDialogComponent } from './filter-ui-dialog/filter-ui-dialog.component';
import { DuplicatePassTemplateDialogComponent } from './duplicate-pass-template-dialog/duplicate-pass-template-dialog.component';
import { DuplicateTaskTemplateDialogComponent } from './duplicate-task-template-dialog/duplicate-task-template-dialog.component';
import { ReportsItemTridysListFiltersComponent } from './reports-item-tridys-list-filters/reports-item-tridys-list-filters.component';
import { MatBadgeModule } from '@angular/material/badge';
import { WorkTaskTemplateDialogIcomingOutgoingComponent } from './work-task-template-dialog-icoming-outgoing/work-task-template-dialog-icoming-outgoing.component';
import { AnnotatedMapAnnotationDynamicDialogComponent } from './widgets/annotated-map-annotation-dynamic-dialog/annotated-map-annotation-dynamic-dialog.component';
import { ContentTypeDisplayTemplateDialogComponent } from './content-type-display-template-dialog/content-type-display-template-dialog.component';
import { ContentTypeDisplayTemplateFieldDialogComponent } from './content-type-display-template-field-dialog/content-type-display-template-field-dialog.component';
import { CdSettingsComponent } from './cd-settings/cd-settings.component';
import { PassTemplatesSettingsEditComponent } from './pass-templates-settings-edit/pass-templates-settings-edit.component';
import { MapAdvancedMarkersComponent } from './map-advanced-markers/map-advanced-markers.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { MapAdvancedMarkersHorizontalComponent } from './map-advanced-markers-horizontal/map-advanced-markers-horizontal.component';
import { PassTemplatesTranslationsComponent } from './pass-templates-translations/pass-templates-translations.component';
import { PassTemplatesTranslationsEditorDialogComponent } from './pass-templates-translations-editor-dialog/pass-templates-translations-editor-dialog.component';
import { TaskTemplateImportFieldEditDialogComponent } from './task-template-import-field-edit-dialog/task-template-import-field-edit-dialog.component';
import { TextTranslationEditComponent } from './widgets-translationMode/text-translation-edit/text-translation-edit.component';
import { ImageTranslationEditComponent } from './widgets-translationMode/image-translation-edit/image-translation-edit.component';
import { AnnotatedMapTranslationEditComponent } from './widgets-translationMode/annotated-map-translation-edit/annotated-map-translation-edit.component';
import { AnnotatedMapAnnotationTranslationDialogComponent } from './widgets-translationMode/annotated-map-annotation-translation-dialog/annotated-map-annotation-translation-dialog.component';
import { ButtonsTranslationEditComponent } from './widgets-translationMode/buttons-translation-edit/buttons-translation-edit.component';

// WYSWIG QUILL
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import QuillMarkdown from 'quilljs-markdown';
Quill.register('modules/markdown', QuillMarkdown);

@NgModule({
  declarations: [
    AppComponent,
    LeftNavbarComponent,
    AccountLanguagesDialogComponent,
    PassTemplatesComponent,
    TridysComponent,
    MediaComponent,
    ProductsComponent,
    PurchasesComponent,
    TaskTemplatesComponent,
    FinishedTasksComponent,
    UsersComponent,
    InjectComponentDirective,
    BillboardImagePhoneComponent,
    ImagesHorizontalPhoneComponent,
    TextPhoneComponent,
    ImagePhoneComponent,
    BillboardVideoPhoneComponent,
    VideoPhoneComponent,
    AnnotatedMapPhoneComponent,
    GooglemapComponent,
    MapMarkerComponent,
    ImagesHorizontalEditComponent,
    BillboardImageEditComponent,
    TextEditComponent,
    ImageEditComponent,
    BillboardVideoEditComponent,
    VideoEditComponent,
    AnnotatedMapEditComponent,
    MapMarkerEditComponent,
    CallToActionEditComponent,
    CallToActionPhoneComponent,
    Model3dTestComponent,
    Model3dPhoneComponent,
    Model3dViewComponent,
    Model3dEditComponent,
    ImageBlocksPhoneComponent,
    ImageBlocksEditComponent,
    PassTemplatesThemeEditComponent,
    DashboardComponent,
    LogInComponent,
    RedirectComponent,
    SettingsDialogComponent,
    TopNavbarComponent,
    CreateUserDialogComponent,
    EditUserDialogComponent,
    ContentTypesListComponent,
    CreateContentTypeDialogComponent,
    ProductRegistrationPhoneComponent,
    ProductRegistrationEditComponent,
    ProductsPhoneComponent,
    ProductsEditComponent,
    ShopifyPhoneComponent,
    InstagramPhoneComponent,
    ContentComponent,
    ContentEditDialogComponent,
    EditValueContentDialogComponent,
    MediaFolderComponent,
    FinishedTasksItemComponent,
    FinishedTasksMapComponent,
    TaskTemplateItemComponent,
    TaskTemplateEditSectionDialogComponent,
    WorkTaskTemplateDialogComponent,
    WorkTaskTemplateEditValueDialogComponent,
    TaskTemplateFieldDialogComponent,
    TridysItemComponent,
    TridysCreateDialogComponent,
    ProductsItemComponent,
    ContextIdSelectorComponent,
    SplashVideoPhoneComponent,
    SplashVideoEditComponent,
    AdvancedExperiencesComponent,
    AdvancedExperiencesItemComponent,
    ImagesPhoneComponent,
    ImagesEditComponent,
    MediaDetailsDialogComponent,
    FilterContentsPipe,
    FilterTridyPipe,
    FilterExperiencesPipe,
    FilterProductsPipe,
    FilterTasksPipe,
    FilterFinishedPipe,
    DeleteContentTypesDialogComponent,
    DeleteConfirmationDialogComponent,
    QrCodeDialogComponent,
    DragAndDropDirective,
    AdvancedExperiencesDialogComponent,
    LanguageEditorDialogComponent,
    LanguageVisualisationDialogComponent,
    InfoMesageDialogComponent,
    ReportsListComponent,
    ReportsItemTridysListComponent,
    ReportCreateDialogComponent,
    ReportAddFilterFieldDialogComponent,
    DuplicateReportTemplateDialogComponent,
    InterfacesListComponent,
    InterfacesDataDialogComponent,
    InterfacesDataMappingDialogComponent,
    TaskTemplateFieldNewOptionDialogComponent,
    AnnotatedMapAnnotationDialogComponent,
    TaskTemplatesGroupsComponent,
    TaskTemplatesGroupsDialogComponent,
    ContentTypesSchemaComponent,
    ContentTypesSchemaPropertiesDialogComponent,
    RegisterComponent,
    RegisterConfirmEmailDialogComponent,
    RegisterSetPasswordComponent,
    RegisterSetPasswordDialogComponent,
    ContentTypesViewDialogComponent,
    ContentTypesSchemaViewDialogComponent,
    AdvancedExperienceViewDialogComponent,
    CancelChangesDialogComponent,
    TaskTemplateCreateDialogComponent,
    WorkTaskTemplateAdvancedDialogComponent,
    SpacerPhoneComponent,
    ButtonsPhoneComponent,
    SpacerEditComponent,
    ButtonsEditComponent,
    UpdateConfirmationDialogComponent,
    ListEditComponent,
    ListPhoneComponent,
    ErrorDialogComponent,
    MapMarkerHorizontalComponent,
    FormPhoneComponent,
    FormEditComponent,
    FormEditFieldDialogComponent,
    FilterUIDialogComponent,
    DuplicatePassTemplateDialogComponent,
    DuplicateTaskTemplateDialogComponent,
    ReportsItemTridysListFiltersComponent,
    WorkTaskTemplateDialogIcomingOutgoingComponent,
    AnnotatedMapAnnotationDynamicDialogComponent,
    ContentTypeDisplayTemplateDialogComponent,
    ContentTypeDisplayTemplateFieldDialogComponent,
    CdSettingsComponent,
    PassTemplatesSettingsEditComponent,
    MapAdvancedMarkersComponent,
    LocationPickerComponent,
    MapAdvancedMarkersHorizontalComponent,
    PassTemplatesTranslationsComponent,
    PassTemplatesTranslationsEditorDialogComponent,
    TaskTemplateImportFieldEditDialogComponent,
    TextTranslationEditComponent,
    ImageTranslationEditComponent,
    AnnotatedMapTranslationEditComponent,
    AnnotatedMapAnnotationTranslationDialogComponent,
    ButtonsTranslationEditComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    HttpClientModule,
    DragDropModule,
    MdbCarouselModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    CommonModule,
    GoogleMapsModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatListModule,
    FontAwesomeModule,
    MatExpansionModule,
    MatSlideToggleModule,
    ColorPickerModule,
    NgxColorsModule,
    MatMenuModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    MatGridListModule ,
    HttpClientModule,
    MatChipsModule,
    QRCodeModule,
    ClipboardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTreeModule,
    MatTooltipModule,
    QuillModule.forRoot({
      modules: {
        markdown: {}
      }
    }),
    MatBadgeModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),

    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    //provideAuth(() => getAuth()),

    // Firestore - With Dev Emulator
/*     provideFirestore(() => {
      const firestore = initializeFirestore(getApp(), {
        experimentalForceLongPolling: isDev ? true : false,
      });

      if (isDev) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
        enableIndexedDbPersistence(firestore);
      }

      return firestore;
    }), */

    // Auth - With Dev Emulator
/*     provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: isDev
          ? browserSessionPersistence
          : indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      });

      if (isDev) connectAuthEmulator(auth, 'http://localhost:9099');

      return auth;
    }), */

    //provideFirestore(() => getFirestore()),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'europe-west1');
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
        console.log("Using emulator for functions");
      }
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    })
  ],
  exports:[
    InjectComponentDirective,
  ],
  providers: [
    CanDeactivateGuard,
    DatePipe,
    { provide: REGION, useValue: 'europe-west1' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: FIRESTORE_SETTINGS, useValue: { ignoreUndefinedProperties: true } },
    // { provide: ANALYTICS_DEBUG_MODE, useValue: true },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
    { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
