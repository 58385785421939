import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserDialogComponent } from '../create-user-dialog/create-user-dialog.component';
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Timestamp } from 'firebase/firestore';
import { NgDialogAnimationService } from "ng-dialog-animation";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { httpsCallable } from '@angular/fire/functions';
import { Functions } from "@angular/fire/functions";
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../service/auth.service';
import { InfoMesageDialogComponent } from '../info-mesage-dialog/info-mesage-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['displayName', 'email', 'creationDate', 'roles', 'status', 'actions'];
  usersUserRights_create: any;
  hostName:any = environment.firebase.projectId
  hideDefaultRole: boolean;
  userLength: number = 0;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private settingsService: SettingsService, 
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private functions: Functions,
    private translate: TranslateService,
    private commentService:CommentsService,
    public animatedDialog: NgDialogAnimationService,) {
      this.dataSource = new MatTableDataSource();
      // used to hide the custom and random role badges on prod
      this.hideDefaultRole = this.hostName === 'narravero-eu-prod';
  }

  ngOnInit(): void {
    this.getUsers();
    this.usersUserRights_create = this.settingsService.userRolesRights$['users']['C'];

  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  convertTimestampToDate(timestamp: any): Date | null {
    if (timestamp instanceof Timestamp) {
      return new Date(timestamp.seconds * 1000);
    }
    return null;  // Return null if timestamp is invalid
  }

  /* getUsers(): void {
    let currentUser = this.settingsService.uid$;
    //console.log('Current user UID:', currentUser);
    
    if (this.settingsService.usersWithContextId$ && this.settingsService.usersWithContextId$.length != 0) {
      // Filter out the current user from the users list
      const filteredUsers = this.settingsService.usersWithContextId$.filter(user => user.id !== currentUser);

      this.dataSource.data = filteredUsers.map(user => ({
        ...user,
        timeStamp: user.timeStamp instanceof Timestamp ? new Date(user.timeStamp.seconds * 1000) : null,
        creationDate: user.creationDate instanceof Timestamp ? new Date(user.creationDate.seconds * 1000) : null
      }));
    } else {
      // Observe users and filter out the current user from the data
      this.settingsService.observeUsersWithcontextId(this.settingsService.contextId$).subscribe(data => {
        const filteredUsers = data.filter(user => user.id !== currentUser);
        
        this.dataSource.data = filteredUsers.map(user => ({
          ...user,
          timeStamp: user.timeStamp instanceof Timestamp ? new Date(user.timeStamp.seconds * 1000) : null,
          creationDate: user.creationDate instanceof Timestamp ? new Date(user.creationDate.seconds * 1000) : null
        }));
      });
    }
    this.userLength = this.dataSource.data.length
  } */

    getUsers(): void {
      let currentUser = this.settingsService.uid$;
    
      // Always observe users from Firestore and filter out the current user
      this.settingsService.observeUsersWithcontextId(this.settingsService.contextId$).subscribe(data => {
        const filteredUsers = data.filter(user => user.id !== currentUser);
    
        this.dataSource.data = filteredUsers.map(user => ({
          ...user,
          timeStamp: user.timeStamp instanceof Timestamp ? new Date(user.timeStamp.seconds * 1000) : null,
          creationDate: user.creationDate instanceof Timestamp ? new Date(user.creationDate.seconds * 1000) : null
        }));
    
        // Ensure that the length of the dataSource is updated
        this.userLength = this.dataSource.data.length;
      }, error => {
        console.error('Error fetching users:', error);
      });
    }
    

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogCreateUser() {
    let dialogRef = this.dialog.open(CreateUserDialogComponent, {});
    dialogRef.afterClosed().subscribe(async (result: any) => {
      this.getUsers();
    });
  }

  editUser(user: any): void {
    let dialogRef = this.animatedDialog.open(EditUserDialogComponent, {
      data: {
        user: user
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0' },
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });
  
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      const updatedUser = {
        displayName: result.displayName || user.displayName,
        roles: result.roles || user.roles
      };

      this.settingsService.updateUser(user.id, updatedUser)
        .then(() => {
          console.log('User updated successfully in Firestore');

          this.dataSource.data = this.dataSource.data.map(u => {
            if (u.id === user.id) {
              return { ...u, ...updatedUser };
            }
            return u;
          });
        })
        .catch(error => {
          console.error('Error updating user:', error);
        });
    }
  });
  }
    
  
  getRoleColor(role: string): string {
    switch (role) {
      case 'admin':
        return 'role-admin';
      case 'betaWidgets':
        return 'role-beta';
      case 'workTaskManager':
        return 'role-wt-manager';
      case 'tracingSupervisor':
        return 'role-supervisor';
      case 'accountManager':
        return 'role-account-manager';
      case 'contentManager':
        return 'role-content-manager';
      case 'tracer':
        return 'role-tracer';
      default:
        // return 'role-default';
        return this.hideDefaultRole ? 'role-hidden' : 'role-default'; 
    }
  }

  getRoleAbbreviation(role: string): string {
    switch (role) {
      case 'admin':
        return 'Admin';
      case 'betaWidgets':
        return 'Beta';
      case 'workTaskManager':
        return 'WM';
      case 'tracingSupervisor':
        return 'TS';
      case 'accountManager':
        return 'AM';
      case 'contentManager':
        return 'CM';
      case 'chatGPT':
        return 'Chat GPT';
      case 'tracer':
        return 'TR';
      default:
        return role;
    }
  }  

  formatRoleName(role: string): string {
    switch (role) {
      case 'admin':
        return 'Admin';
      case 'betaWidgets':
        return 'Beta Widgets';
      case 'workTaskManager':
        return 'Work Task Manager';
      case 'tracingSupervisor':
        return 'Tracing Supervisor';
      case 'accountManager':
        return 'Account Manager';
      case 'contentManager':
        return 'Content Manager';
      case 'tracer':
        return 'Tracer';
      default:
        return role.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, char => char.toUpperCase());
    }
  }

  deleteUser(user: any) {

      let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
        data: { 
          user:user.displayName,
          type:'Users'
        },
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          //console.log('Deletion confirmed for user:', user.id);
          this.commentService.progressSpin.emit(true);
          const userUID = user.id;
          //console.log('Initiating deletion of user with UID:', userUID);
          const deleteUser = httpsCallable(this.functions, 'admin-deleteUser');
          deleteUser({ uid: userUID })
          .then((result) => {
            //console.log(`User with UID: ${userUID} has been successfully deleted from Firestore and Firebase Authentication.`);
            this.dataSource.data = this.dataSource.data.filter(u => u.id !== userUID);

            // Delete user from Firestore 'Users' collection
            this.firestore.collection('Users').doc(user.id).delete()
            .then(() => {
              //console.log(`User with UID: ${user.id} has been deleted from Firestore.`);
              this.dataSource.data = this.dataSource.data.filter(u => u.id !== user.id);
              this.getUsers();
            })
            .catch(error => {
              console.error('Error deleting user from Firestore:', error);
            });

            this.commentService.progressSpin.emit(false);
            const message = this.translate.instant("SnackBarConstants.USER_DELETE_OK");
            this.commentService.addSnackBar.emit(message);
          })
          .catch((error) => {
            console.error('Error deleting user:', error);
            this.commentService.progressSpin.emit(false);
            const message = this.translate.instant("SnackBarConstants.USER_DELETE_FAILED");
            this.commentService.addSnackBar.emit(message);
          });

        }else{
          const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
          this.commentService.addSnackBar.emit(message)
        }
      });

  }  

  resetPassword(email:any){
    if(email){
      this.commentService.progressSpin.emit(true)
      this.authService.resetPassword(email).then( 
        () => {
          this.commentService.progressSpin.emit(false)
          const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_OK")
          this.commentService.addSnackBar.emit(message)
          let dialogRef= this.dialog.open(InfoMesageDialogComponent, {
            data: { 
               values: {
                message: 'PASSWORD_RESET_MAIL_OK',
                mail: email
              }
            },
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        },
        (rejectionReason) => {
          console.log(rejectionReason)
          this.commentService.progressSpin.emit(false)
        }
      ).catch( e => {
        console.log(e)
        const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_FAILED")
        this.commentService.addSnackBar.emit(message)
        this.commentService.progressSpin.emit(false)
      })
    }else{
        const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_FAILED")
        this.commentService.addSnackBar.emit(message)
    }
  }

  disableUser(user: any) {
    //console.log('Disabling user:', user);
    this.commentService.progressSpin.emit(true);
    const userUID = user.id;
    //console.log('User UID:', userUID);
    const disableUser = httpsCallable(this.functions, 'admin-disableUser');
  
    disableUser({ uid: userUID })
      .then((result) => {
        //console.log('User disabled successfully:', result);
        this.settingsService.updateUser(userUID, { state: 'disabled' })
          .then(() => {
            //console.log('User state updated to "disabled" in Firestore');
            this.dataSource.data = this.dataSource.data.map(u => {
              if (u.id === userUID) {
                return { ...u, state: 'disabled' };
              }
              return u;
            });
  
            this.commentService.progressSpin.emit(false);
            const message = this.translate.instant("SnackBarConstants.USER_DISABLE_OK");
            this.commentService.addSnackBar.emit(message);
          })
          .catch((error) => {
            console.error('Error updating user state in Firestore:', error);
            this.commentService.progressSpin.emit(false);
            const message = this.translate.instant("SnackBarConstants.STATE_UPDATE_FAILED");
            this.commentService.addSnackBar.emit(message);
          });
      })
      .catch((error) => {
        console.error('Error disabling user:', error);
        this.commentService.progressSpin.emit(false);
        const message = this.translate.instant("SnackBarConstants.DISABLE_FAILED");
        this.commentService.addSnackBar.emit(message);
      });
  }
  

  enableUser(user: any) {
    //console.log('Enabling user:', user);
    this.commentService.progressSpin.emit(true);
    const userUID = user.id;
    //console.log('User UID:', userUID);
    const enableUser = httpsCallable(this.functions, 'admin-enableUser');
  
    enableUser({ uid: userUID })
      .then((result) => {
        //console.log('User enabled successfully:', result);
        this.settingsService.updateUser(userUID, { state: 'registered' })
          .then(() => {
            //console.log('User state updated to "registered" in Firestore');
  
            this.dataSource.data = this.dataSource.data.map(u => {
              if (u.id === userUID) {
                return { ...u, state: 'registered' };
              }
              return u;
            });

            this.commentService.progressSpin.emit(false);
            const message = this.translate.instant("SnackBarConstants.USER_ENABLE_OK");
            this.commentService.addSnackBar.emit(message);
          })
          .catch((error) => {
            console.error('Error updating user state in Firestore:', error);
  
            this.commentService.progressSpin.emit(false);
            const message = this.translate.instant("SnackBarConstants.STATE_UPDATE_FAILED");
            this.commentService.addSnackBar.emit(message);
          });
      })
      .catch((error) => {
        console.error('Error enabling user:', error);
        this.commentService.progressSpin.emit(false);
        const message = this.translate.instant("SnackBarConstants.ENABLE_FAILED");
        this.commentService.addSnackBar.emit(message);
      });
  }


}
