import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common'; 
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { defaultWidgets } from './../../../../backend/src/defaultWidgets.dto'
import { Template } from '../../../../backend/src/template.dto';
import { CommentsService } from '../service/comments.service';
import { PassDefinitionsService } from '../service/pass-definitions.service';
import { LocalStorageService } from '../service/local-storage-service.service';
import { deepCopy, removeUndefined, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { UploadService } from '../service/upload.service';
import { widgetTypes } from '../widgetTypes';
import { injectGlobal } from '@emotion/css';
import { TranslateService } from '@ngx-translate/core';
import { languagesList } from '../../../../backend/src/languagesList.dto';
import { LanguageVisualisationDialogComponent } from '../language-visualisation-dialog/language-visualisation-dialog.component';
import { environment } from 'src/environments/environment';
import { ContentTypesViewDialogComponent } from '../content-types-view-dialog/content-types-view-dialog.component';
import { AdvancedExperienceViewDialogComponent } from '../advanced-experience-view-dialog/advanced-experience-view-dialog.component';
import { ContentType } from '../../../../backend/src/contentType.dto';
import { ContentTypeService } from '../service/content-type.service';
import { QrCodeDialogComponent } from '../qr-code-dialog/qr-code-dialog.component';
import { CancelChangesDialogComponent } from '../cancel-changes-dialog/cancel-changes-dialog.component';
import { Subscription, Observable } from 'rxjs';
import { NgDialogAnimationService } from "ng-dialog-animation";
import { TridysService } from '../service/tridys.service';
import { Functions, httpsCallable } from "@angular/fire/functions";
import { UpdateConfirmationDialogComponent } from '../update-confirmation-dialog/update-confirmation-dialog.component';
import { Model3dEditComponent } from '../widgets/editWidgets/model3d-edit/model3d-edit.component';
import { ProductsEditComponent } from '../widgets/editWidgets/products-edit/products-edit.component';
import { SplashVideoEditComponent } from '../widgets/editWidgets/splash-video-edit/splash-video-edit.component';
import { ImagesEditComponent } from '../widgets/editWidgets/images-edit/images-edit.component';
import { SpacerEditComponent } from '../widgets/editWidgets/spacer-edit/spacer-edit.component';
import { ButtonsEditComponent } from '../widgets/editWidgets/buttons-edit/buttons-edit.component';
import { ProductRegistrationEditComponent } from '../widgets/editWidgets/product-registration-edit/product-registration-edit.component';
import { ListEditComponent } from '../widgets/editWidgets/list-edit/list-edit.component';
import { CanComponentDeactivate } from '../can-deactivate/can-deactivate.guard';
import { FormEditComponent } from '../widgets/editWidgets/form-edit/form-edit.component';
import { TextEditComponent } from '../widgets/editWidgets/text-edit/text-edit.component';
import { BillboardVideoEditComponent } from '../widgets/editWidgets/billboard-video-edit/billboard-video-edit.component';
import { ImageEditComponent } from '../widgets/editWidgets/image-edit/image-edit.component';
import { AnnotatedMapEditComponent } from '../widgets/editWidgets/annotated-map-edit/annotated-map-edit.component';
import { CallToActionEditComponent } from '../widgets/editWidgets/call-to-action-edit/call-to-action-edit.component';
import { VideoEditComponent } from '../widgets/editWidgets/video-edit/video-edit.component';
import { PassTemplatesThemeEditComponent } from '../pass-templates-theme-edit/pass-templates-theme-edit.component';
import { PassTemplatesSettingsEditComponent } from '../pass-templates-settings-edit/pass-templates-settings-edit.component';
import { BillboardImageEditComponent } from '../widgets/editWidgets/billboard-image-edit/billboard-image-edit.component';
import { TranslationsDPPService } from '../service/translations-dpp.service';
import { deleteField } from '@angular/fire/firestore';

@Component({
  selector: 'app-pass-templates',
  templateUrl: './pass-templates.component.html',
  styleUrls: ['./pass-templates.component.css']
})
export class PassTemplatesComponent implements OnInit, CanComponentDeactivate { 
  isTablet: boolean = false;
  phoneFullHeightView = false;
  darkMode = false;
  passTemplateLanguages:any = []
  passTemplateLanguagesO:any = {}
  private routerSubscription: Subscription;
  oldTemplateData:any
  widgetsNames = widgetTypes
  editTemp=true
  data:any 
  templateData:any
  templateDataPhone!:FormGroup
  translationDisabled:boolean
  all:any
  widgetsTypes:any= []
  alltemplates:any=[]
  currentRoute:any
  themeData:Template
  idToShow:any
  routerUrl:any
  allWidgets:any=[]
  links=[
    "account",
    "passTemplates",
    "contentTypes",
    "tridys",
    "media",
    "products",
    "purchases",
    "taskTemplates",
    "finishedTasks",
    "users"
  ]
  translationMode = false
  idsList:any=[]
  idValue:any = 0
  activeLanguage:any
  passDefaultLanguage:string

  //do not delete comments inside widgetsMenu-------------
  widgetsMenu=[
    {
      name:'Splash Video',
      translate:'pass.widgets.types.splashVideo',
      type:"splashVideo",
      active:true,
    },
    {
      name:'Billboard Video',
      translate:'pass.widgets.types.billboardVideo',
      type:"billboardVideo",
      active:true,
    },
    {
      name:'Billboard Image',
      translate:'pass.widgets.types.billboardImage',
      type:"billboardImage",
      active:true,
    },
    {
      name:'Text',
      translate:'pass.widgets.types.text',
      type:"text",
      active:true,
    },
/*     {
      name: 'Lists',
      translate:'pass.widgets.types.list',
      type:'list',
      active:true
    }, */
    {
      name:'Image',
      translate:'pass.widgets.types.image',
      type:"image",
      active:true,
    },
    {
      name: 'Images',
      translate:'pass.widgets.types.images',
      type:'images',
      active:true
    },
    {
      name:'Video',
      translate:'pass.widgets.types.video',
      type:"video",
      active:true,
    },
    {
      name:'Spacer',
      translate:'pass.widgets.types.spacer',
      type:"spacer",
      active:true,
    },
    {
      name:'Buttons',
      translate:'pass.widgets.types.buttons',
      type:"buttons",
      active:true,
    },
/*     {
      name:'Form',
      translate:'pass.widgets.types.form',
      type:"form",
      active:true,
    }, */
    {
      name:'Map',
      translate:'pass.widgets.types.annotatedMap',
      type:"annotatedMap",
      active:true,
    },

    // {
    //   name:'Notification',
    //   translate:'pass.widgets.types.notification',
    //   type:"notification",
    //   active:false,
    // },
    {
      name:'Social Media',
      translate:'pass.widgets.types.callToAction',
      type:"callToAction",
      active:true,
    }
/* 
    {
      name:'Products',
      translate:'pass.widgets.types.products',
      type:"products",
      active:true,
    },
    {
      name:'Shopify Products',
      translate:'pass.widgets.types.shopifyProducts',
      type:"shopifyProducts",
      active:true,
    },
    {
      name:'Product Registration',
      translate:'pass.widgets.types.productRegistration',
      type:"productRegistration",
      active:true,
    },
    {
      name:'3D Model',
      translate:'pass.widgets.types.3dmodel',
      type:"3dmodel",
      active:true,
    },
    {
      name:'Instagram Feed',
      translate:'pass.widgets.types.instagramFeed',
      type:"instagramFeed",
      active:true,
    } */
    
  ]
  imagesToUpload=[]
  widgetsForm!:FormGroup
  themeForm!:FormGroup
  templateForm!:FormGroup
  name:any//template name for locastorage: Template-{{id}}
  allImgsToUpload:any
  imgsToUpBillboardImage:any=[]
  imgsToUpImage:any=[]
  imgsToUpImageBlocks:any=[]
  imgsToUpImageHorizontal:any=[]
  videoToUpbillboardVideo:any=[]
  videoToUpVideo:any=[]
  imgsToUpText:any=[]
  imgsToUpCallToAction:any=[]
  contextLanguages:any=[]
  contextFirstLanguage:any
  passLanguages:any=[]
  passFirstLanguage:any
  languagesList = languagesList
  languageSelected:any
  langaugeSwitcher=false
  urlFonts:any = {}
  defaultColorsValues:any={
    'accent':'rgb(255, 94, 56)',
    'background':'rgb(255, 255, 255)',
    'title':'rgb(0, 0, 0)',
    'subtitle':'rgb(60, 60, 67,60%)',
    'primary':'rgb(0, 0, 0)',
    'secondary':'rgb(60, 60, 67, 60%)'
  }

  defaultColorsValuesDark:any={
    'accent':'rgb(255, 94, 56)',
    'background':'rgb(0, 0, 0)',//'rgb(255, 255, 255)',
    'title':'rgb(255,255,255)',//'rgb(0, 0, 0)'
    'subtitle':'rgb(129,129,129)',//'rgb(60, 60, 67, 0.60)'
    'primary':'rgb(255,255,255)',//'rgb(0, 0, 0)',
    'secondary':'rgb(129,129,129)'//'rgb(60, 60, 67, 0.60)'
  }
  languagesPassList:any
  translations:any 
  settingsPass:any
  showTranslation=false
  contentTypeId:any
  contentType:any
  oldContentType:any
  unexistingPass=false
  changesToSave=false
  changesCanceled=false
  userHasBetaWidgetRole = false
  tridyCount: any
  titleEdit:any

  constructor(private router: ActivatedRoute,
              private route: Router,
              private location: Location,
              private breakpointObserver: BreakpointObserver,
              public dialog: NgDialogAnimationService,
              private passDefinitionService: PassDefinitionsService,
              public localStorageService: LocalStorageService,
              private settingsService: SettingsService,
              private contentTypeService: ContentTypeService,
              private uploadService: UploadService,
              private fb : FormBuilder,
              private commentService: CommentsService,
              private tridyService: TridysService,
              private translate:TranslateService,
              private functions: Functions,
              private dppTranslations: TranslationsDPPService){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    this.contentTypeId = this.routerUrl.value[this.routerUrl.value.length-3].path  
    this.currentRoute=this.routerUrl.value[0].path

    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge])
    .subscribe(result => {
      this.isTablet = result.matches;
    });
  }

  // add guard to avoid loss of unsaved changes

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.changesToSave) {
      const cancel =  this.openCancelChangesDialog();
      cancel.subscribe(value => {
        if(value){
          this.colorsToDefault()
          this.restoreFonts()
        }
      })
      return cancel
    } else {
      return true; // Allow navigation if no changes
    }
  }

  private openCancelChangesDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(CancelChangesDialogComponent, {
      data: 'passTemplate'
    });

    return dialogRef.afterClosed();
  }

  async ngOnInit(): Promise<void> {
    this.colorsToDefault()
    this.fontsToDefault()
    if(this.settingsService.userRoleBetaWidget){
      this.userHasBetaWidgetRole = true
      const form = {
        name:'Form',
        translate:'pass.widgets.types.form',
        type:"form",
        active:true,
      }

      const lists = {
        name: 'Lists',
        translate:'pass.widgets.types.list',
        type:'list',
        active:true
      }

      const productRegistration = {
        name: 'Product Registration',
        translate:'pass.widgets.types.productRegistration',
        type:'productRegistration',
        active:true
      }

      this.widgetsMenu.push(form)
      this.widgetsMenu.push(lists)
      this.widgetsMenu.push(productRegistration)
    }

    // Stop reload event if there are unsaved changes
    window.onbeforeunload = (event) => {
      if (this.changesToSave && !this.changesCanceled) {
        // Display a confirmation message
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    }; 

    this.templateData = new Template()
    this.alltemplates=this.settingsService.settingsPassDefinitionsWithId
    this.getContentTypeId()

    this.contextLanguages = deepCopy(this.settingsService.languagesContexts$)
    this.languagesPassList = deepCopy(this.contextLanguages)
    this.contextFirstLanguage = this.settingsService.firstLanguageContexts$

    if(this.contextFirstLanguage){
      this.languageSelected = this.contextFirstLanguage
      this.passDefaultLanguage = this.contextFirstLanguage
      this.dppTranslations.setDefaultLanguagePass(this.passDefaultLanguage)
    }

    if(this.settingsService.languagesContexts$){
      this.langaugeSwitcher = true
    }
    try{
      this.commentService.progressSpin.emit(true)
      this.settingsPass = await this.settingsService.getPassDefinitionId(this.idToShow).then(value=> {
       
        if(value){
          this.templateData = new Template()
          this.templateData.setFromAny(value)
  
          if(this.templateData){
            this.passTemplateLanguages.push(this.templateData)
            this.passTemplateLanguagesO['default'] = deepCopy(this.templateData)
            const oldTemplate=this.templateData
            this.oldTemplateData=deepCopy(oldTemplate)

            if(this.templateData.theme)
              this.themeData = this.templateData.theme
      
            if(this.templateData.translations){
              this.translations = deepCopy(this.templateData.translations)
              this.dppTranslations.initializePassTranslatedValues(this.translations)
            }
      
            if(this.templateData.widgets){
              this.templateData.widgets.forEach((element:any) => {
                this.widgetsTypes.push(element.type)
              });
            }
      
      
            if(this.templateData.languages){
              this.passLanguages = deepCopy(this.templateData.languages)
              this.languagesPassList=deepCopy(this.passLanguages)
      
              this.dppTranslations.initializePassLanguages(this.passLanguages) 

              if(this.templateData.languages.length>0){
                this.passFirstLanguage = this.templateData.languages[0]
                this.passDefaultLanguage = this.passFirstLanguage
                this.dppTranslations.setDefaultLanguagePass(this.passDefaultLanguage)
                // this.dppTranslations.initializePassFirstLanguage(this.passFirstLanguage)

                if(this.passFirstLanguage){
                  this.languageSelected = this.passFirstLanguage
                }
                this.langaugeSwitcher = true
              }
              else {
                this.langaugeSwitcher = false
              }
            }
            this.editTemplate();
          }
          const oldTemplate=this.templateData
          this.oldTemplateData=deepCopy(oldTemplate)
      
          this.updateColors()
          this.updateColorsDark()
          this.updateFonts()
          // this.selectLeftNavbar()
        }else{
          this.unexistingPass = true
          const data = {
            name : this.contentType.displayName,
            widgets:[],
            contextId: this.settingsService.contextId$,
            id:this.idToShow
          }

          this.templateData = new Template()
          this.templateData.setFromAny(data)

          if(this.templateData){
            this.passTemplateLanguages.push(this.templateData)
            this.passTemplateLanguagesO['default'] = deepCopy(this.templateData)
            const oldTemplate=this.templateData
            this.oldTemplateData=deepCopy(oldTemplate)

            if(this.templateData.widgets){
              this.templateData.widgets.forEach((element:any) => {
                this.widgetsTypes.push(element.type)
              });
            }

            this.editTemplate();
          }
          const oldTemplate=this.templateData
          this.oldTemplateData=deepCopy(oldTemplate)

          this.updateColors()
          this.updateColorsDark()
          this.updateFonts()
        }

      }).catch((e)=>{
        console.log(e)
        this.commentService.progressSpin.emit(false)
      })

    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
    }

    this.allImgsToUpload = new Map([
      [widgetTypes.billboardImage, this.imgsToUpBillboardImage],
      [widgetTypes.image, this.imgsToUpImage],
      [widgetTypes.imageBlocks, this.imgsToUpImageBlocks],
      [widgetTypes.text, this.imgsToUpText],
      [widgetTypes.callToAction, this.imgsToUpCallToAction],
      [widgetTypes.imagesHorizontal, this.imgsToUpImageHorizontal],
      [widgetTypes.billboardVideo,this.videoToUpbillboardVideo],
      [widgetTypes.video,this.videoToUpVideo]
    ])


    ///// CREATE TRIDY ON INIT ///////
    const tridyContent = {
      contentType: this.idToShow,
      contextId: this.settingsService.contextId$
    }

    if(this.contentTypeId){
      try{
        this.tridyService.createTridyFromContentType(tridyContent, this.contentTypeId)
      }catch(error){
        console.log(error)
      }
      //-------do not delete this----------------------------------------------------------------------
      //uncomment this to show the warning message: this pass has x tridys, are you sure you want to update?
      // this.countTridysPass()
    }

    if(this.darkMode){
      this.setColorsToDark()
    }else{
      this.setColorsToLight()
    }

    this.checkTranslationAvailability()

  }

  checkTranslationAvailability(){
    if(this.passLanguages && this.passLanguages.length>1){
      this.translationDisabled = false
    }else{
      if(!this.passLanguages || this.passLanguages.length ==0 ){
        if(this.contextLanguages && this.contextLanguages.length>1)
          this.translationDisabled = false
        if(!this.contextLanguages){
          this.translationDisabled = true
        }
        if(this.contextLanguages && this.contextLanguages.length==1){
          this.translationDisabled = true
        }
      }
      if(this.passLanguages && this.passLanguages.length==1){
        this.translationDisabled = true
      }

      if(this.passLanguages && this.passLanguages.length==0 && this.contextLanguages && this.contextLanguages.length ==0 ){
        this.translationDisabled = true
      }
    }
  }

  async getContentTypeId(){
    try{
      this.commentService.progressSpin.emit(true)
      this.contentType = await this.settingsService.observeContextsContentTypesIdFirestore(this.contentTypeId)
      if(this.contentType.displayName){
        this.titleEdit = this.contentType.displayName
      }
      this.oldContentType = new ContentType()
      this.oldContentType.setFromAny(this.contentType)

      this.commentService.progressSpin.emit(false)

    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  initializeForms(){
    this.idsList=[]
    this.widgetsForm = this.fb.group({})

    if(this.templateData && this.templateData.widgets){
      this.templateData.widgets.forEach((w, index)=>{
        this.widgetsForm.value[index]=w
        this.allWidgets.push(w)
        this.idsList.push(w.id)

      })
    }

    this.templateForm = this.fb.group({
      contextId: [],
      id: [],
      theme: [],
      comment: [],
      name: [],
      contentType: [],
      customer: [],
      tridyId: [],
      widgets:[], 
      translations:[],
      languages:[],
      enableShoppingCart:[],
      enableCustomerLogin:[]
    })

    const {id, contextId, theme, comment, name, contentType, customer, tridyId, widgets, translations,languages,enableShoppingCart,enableCustomerLogin} = this.templateData
    this.templateForm.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : undefined,
      theme: theme ? theme : undefined,
      comment: comment ? comment : undefined,
      name: name ? name : undefined,
      contentType: contentType ? contentType : this.contentTypeId,
      customer: customer ? customer : undefined,
      tridyId: tridyId ? tridyId : undefined,
      widgets: widgets ? widgets : undefined,
      translations: translations ? translations : undefined,
      languages:languages ? languages : undefined,
      enableShoppingCart: enableShoppingCart ? enableShoppingCart : false,
      enableCustomerLogin: enableCustomerLogin ? enableCustomerLogin : false

    })

    this.setWidgetSpacingCornerRadius(theme)
    this.templateForm.valueChanges.subscribe(values=> {
      this.changesToSave=true
    })

    this.templateForm.get('languages').valueChanges.subscribe(values=> {
      // console.log('languages',values)
      this.dppTranslations.updatepassLanguagesList(languages)
    })

    if(this.templateForm.value.languages && this.templateForm.value.languages.length!=0){
      this.passDefaultLanguage = this.templateForm.value.languages[0]
      this.dppTranslations.setDefaultLanguagePass(this.passDefaultLanguage)
    }
  }

  setWidgetSpacingCornerRadius(theme){
    if(theme){
      if(theme.widgetSpacing){
        document.documentElement.style.setProperty('--widgetSpacing',theme.widgetSpacing+'px')
      }
      else if(theme.widgetSpacing === 0){
        document.documentElement.style.setProperty('--widgetSpacing','0')
      }else{
        document.documentElement.style.setProperty('--widgetSpacing','20px')
      }
  
      if(theme.cornerRadius){
        document.documentElement.style.setProperty('--cornerRadius',theme.cornerRadius+'px')
      }
      else if(theme.cornerRadius === 0){
        document.documentElement.style.setProperty('--cornerRadius','0')
      }else{
        document.documentElement.style.setProperty('--cornerRadius','10px')
      }
    }else{
      document.documentElement.style.setProperty('--cornerRadius','10px')
      document.documentElement.style.setProperty('--widgetSpacing','20px')
    }

  }

  changeTheme(value){///change this after change toggle dark(light)
    this.darkMode=value
    if(this.darkMode){
      this.setColorsToDark()
    }else{
      this.setColorsToLight()
    }
  }

  setColorsToDark(){

    if(this.themeData && this.themeData['darkColors']){
      if(this.themeData['darkColors'].accent)
        document.documentElement.style.setProperty('--accent',this.themeData['darkColors'].accent)
      else
        document.documentElement.style.setProperty('--accent',this.defaultColorsValuesDark.accent)

      if(this.themeData['darkColors'].background)
        document.documentElement.style.setProperty('--background',this.themeData['darkColors'].background)
      else
        document.documentElement.style.setProperty('--background',this.defaultColorsValuesDark.background)

      if(this.themeData['darkColors'].title)
        document.documentElement.style.setProperty('--title',this.themeData['darkColors'].title)
      else
        document.documentElement.style.setProperty('--title',this.defaultColorsValuesDark.title)

      if(this.themeData['darkColors'].subtitle)
        document.documentElement.style.setProperty('--subtitle',this.themeData['darkColors'].subtitle)
      else
        document.documentElement.style.setProperty('--subtitle',this.defaultColorsValuesDark.subtitle)

      if(this.themeData['darkColors'].primary)
        document.documentElement.style.setProperty('--primary',this.themeData['darkColors'].primary)
        else
        document.documentElement.style.setProperty('--primary',this.defaultColorsValuesDark.primary)

      if(this.themeData['darkColors'].secondary)
        document.documentElement.style.setProperty('--secondary',this.themeData['darkColors'].secondary)
      else
        document.documentElement.style.setProperty('--secondary',this.defaultColorsValuesDark.secondary)
    }
    else{
      document.documentElement.style.setProperty('--accent',this.defaultColorsValuesDark.accent)
      document.documentElement.style.setProperty('--background',this.defaultColorsValuesDark.background)
      document.documentElement.style.setProperty('--title',this.defaultColorsValuesDark.title)
      document.documentElement.style.setProperty('--subtitle',this.defaultColorsValuesDark.subtitle)
      document.documentElement.style.setProperty('--primary',this.defaultColorsValuesDark.primary)
      document.documentElement.style.setProperty('--secondary',this.defaultColorsValuesDark.secondary)

    }
  }

  setColorsToLight(){
    if(this.themeData && this.themeData['lightColors']){
      if(this.themeData['lightColors'].accent)
        document.documentElement.style.setProperty('--accent',this.themeData['lightColors'].accent)
      else
        document.documentElement.style.setProperty('--accent',this.defaultColorsValues.accent)

      if(this.themeData['lightColors'].background)
        document.documentElement.style.setProperty('--background',this.themeData['lightColors'].background)
      else
        document.documentElement.style.setProperty('--background',this.defaultColorsValues.background)

      if(this.themeData['lightColors'].title)
        document.documentElement.style.setProperty('--title',this.themeData['lightColors'].title)
      else
        document.documentElement.style.setProperty('--title',this.defaultColorsValues.title)

      if(this.themeData['lightColors'].subtitle)
        document.documentElement.style.setProperty('--subtitle',this.themeData['lightColors'].subtitle)
      else
        document.documentElement.style.setProperty('--subtitle',this.defaultColorsValues.subtitle)

      if(this.themeData['lightColors'].primary)
        document.documentElement.style.setProperty('--primary',this.themeData['lightColors'].primary)
      else
        document.documentElement.style.setProperty('--primary',this.defaultColorsValues.primary)

      if(this.themeData['lightColors'].secondary)
        document.documentElement.style.setProperty('--secondary',this.themeData['lightColors'].secondary)
      else
        document.documentElement.style.setProperty('--secondary',this.defaultColorsValues.secondary)
      
    }
    else{
      document.documentElement.style.setProperty('--accent',this.defaultColorsValues.accent)
      document.documentElement.style.setProperty('--background',this.defaultColorsValues.background)
      document.documentElement.style.setProperty('--title',this.defaultColorsValues.title)
      document.documentElement.style.setProperty('--subtitle',this.defaultColorsValues.subtitle)
      document.documentElement.style.setProperty('--primary',this.defaultColorsValues.primary)
      document.documentElement.style.setProperty('--secondary',this.defaultColorsValues.secondary)

    }
  }

  updateColors(){
    if(this.themeData && this.themeData['lightColors']){
      if(this.themeData['lightColors'].accent)
        document.documentElement.style.setProperty('--accentLight',this.themeData['lightColors'].accent)
      if(this.themeData['lightColors'].background)
        document.documentElement.style.setProperty('--backgroundLight',this.themeData['lightColors'].background)
      if(this.themeData['lightColors'].title)
        document.documentElement.style.setProperty('--titleLight',this.themeData['lightColors'].title)
      if(this.themeData['lightColors'].subtitle)
        document.documentElement.style.setProperty('--subtitleLight',this.themeData['lightColors'].subtitle)
      if(this.themeData['lightColors'].primary)
        document.documentElement.style.setProperty('--primaryLight',this.themeData['lightColors'].primary)
      if(this.themeData['lightColors'].secondary)
        document.documentElement.style.setProperty('--secondaryLight',this.themeData['lightColors'].secondary)
    }else{
      this.colorsToDefaultLight()
    }
  }

  updateColorsDark(){
    if(this.themeData && this.themeData['darkColors']){
      if(this.themeData['darkColors'].accent)
        document.documentElement.style.setProperty('--accentDark',this.themeData['darkColors'].accent)
      if(this.themeData['darkColors'].background)
        document.documentElement.style.setProperty('--backgroundDark',this.themeData['darkColors'].background)
      if(this.themeData['darkColors'].title)
        document.documentElement.style.setProperty('--titleDark',this.themeData['darkColors'].title)
      if(this.themeData['darkColors'].subtitle)
        document.documentElement.style.setProperty('--subtitleDark',this.themeData['darkColors'].subtitle)
      if(this.themeData['darkColors'].primary)
        document.documentElement.style.setProperty('--primaryDark',this.themeData['darkColors'].primary)
      if(this.themeData['darkColors'].secondary)
        document.documentElement.style.setProperty('--secondaryDark',this.themeData['darkColors'].secondary)
    }else{
      this.colorsToDefaultDark()
    }
  }

  colorsToDefault(){
    for (const key in this.defaultColorsValues) {
      document.documentElement.style.setProperty('--' + key, this.defaultColorsValues[key]);
    }
  
  }
  colorsToDefaultDark(){
    document.documentElement.style.setProperty('--accentDark',this.defaultColorsValuesDark.accent)
    document.documentElement.style.setProperty('--backgroundDark',this.defaultColorsValuesDark.background)
    document.documentElement.style.setProperty('--titleDark',this.defaultColorsValuesDark.title)
    document.documentElement.style.setProperty('--subtitleDark',this.defaultColorsValuesDark.subtitle)
    document.documentElement.style.setProperty('--primaryDark',this.defaultColorsValuesDark.primary)
    document.documentElement.style.setProperty('--secondaryDark',this.defaultColorsValuesDark.secondary)

  }
  colorsToDefaultLight(){
    document.documentElement.style.setProperty('--accentLight',this.defaultColorsValues.accent)
    document.documentElement.style.setProperty('--backgroundLight',this.defaultColorsValues.background)
    document.documentElement.style.setProperty('--titleLight',this.defaultColorsValues.title)
    document.documentElement.style.setProperty('--subtitleLight',this.defaultColorsValues.subtitle)
    document.documentElement.style.setProperty('--primaryLight',this.defaultColorsValues.primary)
    document.documentElement.style.setProperty('--secondaryLight',this.defaultColorsValues.secondary)
  }

  updateFonts(){
    if(this.themeData && this.themeData['fonts']){
      if(this.themeData['fonts'].title && this.themeData['fonts'].title.name && this.themeData['fonts'].title.url ){
        this.supportUrl(this.themeData['fonts'].title.url, 'title')
      }else{
        if(document.getElementById('title')){
          document.getElementById('title').style.fontFamily = "'Roboto', sans-serif"
        }else{
          injectGlobal`
            #title {
              font-family: 'Roboto', sans-serif; 
            }
          `
        }
        // title
        document.documentElement.style.setProperty('--titleFontName',"'Roboto', sans-serif")
        document.documentElement.style.setProperty('--titleFontUrl',"")
        document.documentElement.style.setProperty('--titleFontUrlsrc',"")
      }

      if(this.themeData['fonts'].subtitle && this.themeData['fonts'].subtitle.name && this.themeData['fonts'].subtitle.url ){
        this.supportUrl(this.themeData['fonts'].subtitle.url, 'subtitle')
      }else{
        if(document.getElementById('subtitle')){
        document.getElementById('subtitle').style.fontFamily = "'Roboto', sans-serif"
        }else{
        injectGlobal`
          #subtitle {
            font-family: 'Roboto', sans-serif; 
          }
        `}
        //subtitle
        document.documentElement.style.setProperty('--subtitleFontName',"'Roboto', sans-serif")
        document.documentElement.style.setProperty('--subtitleFontUrl',"")
        document.documentElement.style.setProperty('--subtitleFontUrlsrc',"")
      }

      if(this.themeData['fonts'].plain && this.themeData['fonts'].plain.name && this.themeData['fonts'].plain.url ){
        this.supportUrl(this.themeData['fonts'].plain.url, 'plain')
      }else{

        if(document.getElementById('plain')){
          document.getElementById('plain').style.fontFamily = "'Roboto', sans-serif"
          }else{
        injectGlobal`
          #plain {
            font-family: 'Roboto', sans-serif; 
          }
        `}
        // plain
        document.documentElement.style.setProperty('--plainFontName',"'Roboto', sans-serif")
        document.documentElement.style.setProperty('--plainFontUrl',"")
        document.documentElement.style.setProperty('--plainFontUrlsrc',"")
      }

      if(this.themeData['fonts'].bold && this.themeData['fonts'].bold.name && this.themeData['fonts'].bold.url ){
        this.supportUrl(this.themeData['fonts'].bold.url, 'bold')
      }else{
        if(document.getElementById('bold')){
          document.getElementById('bold').style.fontFamily = "'Roboto', sans-serif"
          }else{
        injectGlobal`
          #bold {
            font-family: 'Roboto', sans-serif; 
          }
        `}
        // bold
        document.documentElement.style.setProperty('--boldFontName',"'Roboto', sans-serif")
        document.documentElement.style.setProperty('--boldFontUrl',"")
        document.documentElement.style.setProperty('--boldFontUrlsrc',"")
      }

      if(this.themeData['fonts'].italic && this.themeData['fonts'].italic.name && this.themeData['fonts'].italic.url ){
        this.supportUrl(this.themeData['fonts'].italic.url, 'italic')
      }else{
        if(document.getElementById('italic')){
          document.getElementById('italic').style.fontFamily = "'Roboto', sans-serif"
          }else{
        injectGlobal`
          #italic {
            font-family: 'Roboto', sans-serif; 
          }
        `
      }
        // italic
        document.documentElement.style.setProperty('--italicFontName',"Roboto")
        document.documentElement.style.setProperty('--italicFontUrl',"")
        document.documentElement.style.setProperty('--italicFontUrlsrc',"")
      }
        
    }else{
      this.restoreFonts()
    }
  }

  restoreFonts(){
    this.uploadService.emptyFonts()
    // title
    document.documentElement.style.setProperty('--titleFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--titleFontUrl',"")
    document.documentElement.style.setProperty('--titleFontUrlsrc',"")

    //subtitle
    // document.documentElement.style.removeProperty('subtitle')
    document.documentElement.style.setProperty('--subtitleFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--subtitleFontUrl',"")
    document.documentElement.style.setProperty('--subtitleFontUrlsrc',"")

    // plain
    // document.documentElement.style.removeProperty('plain')
    document.documentElement.style.setProperty('--plainFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--plainFontUrl',"")
    document.documentElement.style.setProperty('--plainFontUrlsrc',"")

    // bold
    // document.documentElement.style.removeProperty('bold')
    document.documentElement.style.setProperty('--boldFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--boldFontUrl',"")
    document.documentElement.style.setProperty('--boldFontUrlsrc',"")

    // italic
    // document.documentElement.style.removeProperty('italic')
    document.documentElement.style.setProperty('--italicFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--italicFontUrl',"")
    document.documentElement.style.setProperty('--italicFontUrlsrc',"")

    if(document.getElementById('title'))
      document.getElementById('title').style.fontFamily = "'Roboto', sans-serif"
  
    if(document.getElementById('subtitle'))
      document.getElementById('subtitle').style.fontFamily = "'Roboto', sans-serif"

    if(document.getElementById('plain'))
      document.getElementById('plain').style.fontFamily = "'Roboto', sans-serif"

    if(document.getElementById('bold'))
      document.getElementById('bold').style.fontFamily = "'Roboto', sans-serif"

    if(document.getElementById('italic'))
      document.getElementById('italic').style.fontFamily = "'Roboto', sans-serif"
   
        injectGlobal`
          #title {
            font-family: var(--titleFontName); 
          }
        `
        injectGlobal`
          #subtitle {
            font-family: var(--subtitleFontName); 
          }
        `

        injectGlobal`
          #plain {
            font-family: var(--plainFontName); 
          }
        `
        injectGlobal`
          #italic {
            font-family: var(--italicFontName); 
          }
        `
        injectGlobal`
          #bold {
            font-family: var(--boldFontName); 
 
          }
        `

  }

  fontsToDefault(){
    // title
    document.documentElement.style.setProperty('--titleFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--titleFontUrl',"")
    document.documentElement.style.setProperty('--titleFontUrlsrc',"")

    //subtitle
    document.documentElement.style.setProperty('--subtitleFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--subtitleFontUrl',"")
    document.documentElement.style.setProperty('--subtitleFontUrlsrc',"")

    // plain
    document.documentElement.style.setProperty('--plainFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--plainFontUrl',"")
    document.documentElement.style.setProperty('--plainFontUrlsrc',"")

    // bold
    document.documentElement.style.setProperty('--boldFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--boldFontUrl',"")
    document.documentElement.style.setProperty('--boldFontUrlsrc',"")

    // italic
    document.documentElement.style.setProperty('--italicFontName',"'Roboto', sans-serif")
    document.documentElement.style.setProperty('--italicFontUrl',"")
    document.documentElement.style.setProperty('--italicFontUrlsrc',"")

    injectGlobal`
      #title {
        font-family: var(--titleFontName); 
      }
    `
    injectGlobal`
      #subtitle {
        font-family: var(--subtitleFontName); 
      }
    `

    injectGlobal`
      #plain {
        font-family: var(--plainFontName); 
      }
    `
    injectGlobal`
      #italic {
        font-family: var(--italicFontName); 
      }
    `
    injectGlobal`
      #bold {
        font-family: var(--boldFontName); 

      }
    `
  }

  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.allWidgets, event.previousIndex, event.currentIndex);
    this.updateFormValue()
  }

  deleteWidget(el:any, index){
    // Remove registeredView storage key
    const widgetId = this.allWidgets[index].id;
    const registeredViewKey = `registeredView_${this.idToShow}_${widgetId}`;
    this.localStorageService.remove(registeredViewKey);
    // Default function
    this.allWidgets.splice(index,1)
    this.idsList.splice(index,1)
    this.updateFormValue()
  }

  updateFormValue(){
    let widgets=[]
    this.widgetsForm.reset()
    this.allWidgets.forEach((w:any,ind:any) =>{
      // this.widgetsForm.value[ind]=w
      widgets.push(w)
    })
    this.widgetsForm.patchValue(widgets)
    this.templateForm.patchValue({
      widgets: this.allWidgets
    })

  }

  checkTypeNumber(el, indexWidget){
    let typeNumber = 1
    this.allWidgets.forEach((widget, index) => {
      if(index<indexWidget){
        if(widget.type == el.type){
          typeNumber ++
        }
      }
    })
    return typeNumber.toString()
  }

  updateIds(){
    this.allWidgets.forEach( (widget, index) => {
      widget.id = this.idsList[index]
    })
    this.updateFormValue()
  }

  editTemplate(){
    this.templateData = new Template ()
    this.templateData.setFromAny(this.passTemplateLanguagesO['default'])
    if(this.passLanguages.length!=0){
      this.languageSelected = this.passLanguages[0]
    }else{
      this.languageSelected = this.contextFirstLanguage
    }
    this.editTemp=true
    const oldTemplate=this.templateData
    this.oldTemplateData=deepCopy(oldTemplate)
    this.name="Template-"+this.templateData.id
    this.initializeForms()
  }
  
  backTo(){
    this.location.back()
    // this.colorsToDefault()
    // this.restoreFonts()
  }

  openDialogEditTheme():void{
    let dialogRef= this.dialog.open(PassTemplatesThemeEditComponent, {
      // data:[this.templateForm.value,this.settingsService.contextId$ ],
      data:{
        formData: this.templateForm.value,
        oldData:this.oldTemplateData,
        contextId: this.settingsService.contextId$ 
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      if(value[0]){
        const result = value[1]
        this.templateForm.patchValue({theme: result.theme,languages: result.languages , comment: result.comment, name: result.name, enableShoppingCart: result.enableShoppingCart,enableCustomerLogin: result.enableCustomerLogin }) 
        if(result.languages){
          this.passLanguages=result.languages
          this.languagesPassList=this.passLanguages
          this.passFirstLanguage = result.languages[0]
            if(this.passFirstLanguage){
              this.languageSelected = this.passFirstLanguage
            }
        }
        this.themeData = this.templateForm.value.theme
      
        this.updateColors()
        this.updateColorsDark()

        if(this.darkMode){
          this.setColorsToDark()
        }else{
          this.setColorsToLight()
        }

      this.updateFonts()
        this.setWidgetSpacingCornerRadius(result.theme)
      }
    });
  }

  openDialogEditSettings():void{
    let dialogRef= this.dialog.open(PassTemplatesSettingsEditComponent, {
      data:[this.templateForm.value,this.settingsService.contextId$ ],
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      if(value[0]){
        const result = value[1]
        this.templateForm.patchValue({
          comment: result.comment, 
          name: result.name, 
          enableShoppingCart: result.enableShoppingCart,
          enableCustomerLogin: result.enableCustomerLogin,
          languages: result.languages
        }) 
          //this.templateData.languages = this.templateForm.value.languages
          //console.log(this.passLanguages)
          this.passLanguages = this.templateForm.value.languages
          this.languagesPassList = this.templateForm.value.languages
          if(this.templateForm.value.languages && this.templateForm.value.languages.length!=0){
            this.passDefaultLanguage = this.templateForm.value.languages[0]
            this.dppTranslations.setDefaultLanguagePass(this.passDefaultLanguage)
          }else{
            this.passDefaultLanguage = this.contextFirstLanguage
          }
          this.checkTranslationAvailability()
      }
    });
  }

/*   openContentTypeView():void{
    let dialogRef= this.dialog.open(ContentTypesViewDialogComponent, {
      data:{
        contentType: this.contentType,
        template: this.templateForm.value,
        contextId: this.settingsService.contextId$,
        contentTypeId: this.contentTypeId
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.contentType = result[1]
        this.updateContentType(result[1])
      }
    });
  } */

  openDialogCoverImage():void{
    if(this.contentType){
        let dialogRef= this.dialog.open(AdvancedExperienceViewDialogComponent, {
          disableClose: true,
          data:{
            template: this.templateForm.value,
            contextId: this.settingsService.contextId$,
            contentTypeId: this.contentTypeId,
            contentType: this.contentType
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result){
            this.getContentTypeId()  
          }
        });
    }
  }

  clickList(elem:any, index){
    var dialogEditName:any
    var data:any
   
    //lets open the edit dialog for each one
    switch (elem.type){
        case 'billboardImage': {
          dialogEditName=BillboardImageEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // languages:languages
            // translations: this.templateForm.value.translations
          }
          break;
        }
        case 'billboardVideo': {
          dialogEditName=BillboardVideoEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // languages:languages
            // translations: this.templateForm.value.translations
          }
          break;
        }

        case 'buttons': {
          dialogEditName=ButtonsEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage,
            languages:this.languagesPassList,
            translations: this.templateForm.value.translations
          }
          break;
        }

        case 'text': {
          dialogEditName=TextEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage,
            translations: this.templateForm.value.translations,
            languages:this.languagesPassList,
            contentTypeId: this.contentTypeId
          }
          break;
        }
        case 'image': {
          dialogEditName=ImageEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage,
            translations: this.templateForm.value.translations,
            languages:this.languagesPassList
          }
          break;
        }
        case 'annotatedMap': {
          dialogEditName=AnnotatedMapEditComponent
          data= { 
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage,
            translations: this.templateForm.value.translations,
            languages:this.languagesPassList
          }
          break;
        }
        case 'callToAction': {
          dialogEditName=CallToActionEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }
        case 'products': {
          dialogEditName=ProductsEditComponent
          data = {
            values:elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations,
            // languages:this.languagesPassList
          }
          break;
        }
        case 'productRegistration': {
          dialogEditName=ProductRegistrationEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage,
            languages:this.languagesPassList,
            translations: this.templateForm.value.translations
          }
          break;
        }
        case 'shopifyProducts': {
          dialogEditName="ShopifyProductsEditComponent"
          break;
        }
        case 'video': {
          dialogEditName=VideoEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage,
            // translations: this.templateForm.value.translations
          }
          break;
        }
        case 'splashVideo': {
          dialogEditName=SplashVideoEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }
        case 'spacer': {
          dialogEditName=SpacerEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }
        case 'imageBlocks': {
          dialogEditName=ImagesEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations

          }
          break;
        }
        case 'imagesHorizontal': {
          dialogEditName=ImagesEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }

        case 'images': {
          dialogEditName=ImagesEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }
        case '3dmodel': {
          dialogEditName=Model3dEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }

        case 'list': {
          dialogEditName=ListEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }

        case 'form': {
          dialogEditName=FormEditComponent
          data = {
            values: elem,
            widget:index,
            passId: this.idToShow,
            defaultLanguage: this.passDefaultLanguage
            // translations: this.templateForm.value.translations
          }
          break;
        }

        // case 'instagramFeed': {
        //   dialogEditName="InstagramFeedEditComponent"
        //   break;
        // }
    }

    let dialogRef = this.dialog.open(dialogEditName, {
      data:data,
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        let translationsnew
        if(result[3]){
          translationsnew = result[3]
        }
        const event = {newdata: result[0], widget: index, widgetType: elem.type, translations: translationsnew}
        this.returnChangesActive(event)
      }      
    })
  }

  QRoptions(){
    const content = {
      contentType: this.idToShow,
      contextId: this.settingsService.contextId$
    }
    let dialogRef= this.dialog.open(QrCodeDialogComponent, {
      data: {
        contentType:content,
        id: this.contentTypeId,
        nfcPrefix: this.contentType.nfcURLPrefix
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  addWidget(name){
    let id = this.makeid(6)
    this.idValue = this.idValue +1
    // if(this.idsList.includes(id)){
      id = id + (this.idValue+1) + ""
    // }
    this.idsList.push(id)

    const index = Object.keys(this.templateForm.value.widgets).length
    const newWidget = deepCopy(defaultWidgets[name])
    newWidget['id']=this.idsList[index]

    if(name=="buttons"){
      newWidget.buttons[0].id = this.makeid(10)
    }

/*     if(name=="productRegistration"){
      newWidget.productRegistration[0].id = this.makeid(10)
    } */

    this.allWidgets.push(newWidget)
    this.updateIds()
  }

  showTranslatedPass(lang){
    const template = new Template()
    const copydefault = deepCopy(this.settingsPass[0])
    template.setFromAny(copydefault)

    let teform = this.fb.group({
      contextId: [],
      id: [],
      theme: [],
      comment: [],
      name: [],
      contentType: [],
      customer: [],
      tridyId: [],
      widgets:[], 
      translations:[],
      languages:[]
    })

    const {id, contextId, theme, comment, name, contentType, customer, tridyId, widgets, translations,languages} = template
    teform.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : undefined,
      theme: theme ? theme : undefined,
      comment: comment ? comment : undefined,
      name: name ? name : undefined,
      contentType: contentType ? contentType : undefined,
      customer: customer ? customer : undefined,
      tridyId: tridyId ? tridyId : undefined,
      widgets: widgets ? widgets : undefined,
      translations: translations ? translations : undefined,
      languages: languages ? languages : undefined
    })
    // const translations = this.templateData.translations
    // this.languageSelected = lang//coment to prevent select to chnage language select 12.07
    if(this.editTemp==false){
      if(this.templateData.translations && this.templateData.translations[lang] ){
        const entries = Object.entries(this.templateData.translations[lang])
        
        entries.forEach( translation => {
          const idField = translation[0].split(".")
          const id = idField[0]
          const field = idField[1]
          const value = translation[1]

          if(teform.value.widgets){
            const widgets = teform.value.widgets
            widgets.forEach( (widget, index) => {
              if(widget['id'] == id){
                widget[field] = value
              }
            })
            teform.patchValue({widgets: widgets})
          }
          
        })

      if(!this.passTemplateLanguagesO[lang])
        this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }else{

        this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }
    }
  }

  changePassLanguage(lang){
    if(this.editTemp){
      // this.languageSelected=lang//coment to prevent select to chnage language select 12.07
    }else{
      this.templateData = new Template()
      this.templateData.setFromAny(this.passTemplateLanguagesO['default'])
      this.showTranslatedPass(lang)
      if(!this.passTemplateLanguagesO[lang]){
        this.templateData = new Template()
        this.templateData.setFromAny(this.passTemplateLanguagesO['default'])
      }else{
        // this.languageSelected = lang//coment to prevent select to chnage language select 12.07
        this.templateData = new Template()
        this.templateData.setFromAny(this.passTemplateLanguagesO[lang])
      }
    }

    
  }

  async saveEditTemplateValues(){
    this.editTemp=false
    const value = this.templateForm.value
    const {id, contextId, theme, comment, name, contentType, customer, tridyId, widgets, translations, languages,enableCustomerLogin,enableShoppingCart} = value
    const newPasDefinitonValues = {
      contextId: contextId ,
      id: id ,
      theme: theme ,
      comment: comment ,
      name: name ,
      contentType: contentType ,
      customer: customer ,
      tridyId: tridyId ,
      widgets: widgets ,
      translations: translations,
      languages: languages,
      enableShoppingCart:enableShoppingCart,
      enableCustomerLogin:enableCustomerLogin
    }

    //remove lat, lng values of maps before saving on firestore
    const newPasDefinitonValuesCleanedMap = deepCopy(newPasDefinitonValues)
    if(newPasDefinitonValuesCleanedMap.widgets){
      newPasDefinitonValuesCleanedMap.widgets.forEach(widget => {
        if(widget.type == 'annotatedMap'){
          if(widget.annotations){
            widget.annotations.forEach(annotation => {
              if(annotation.lat && annotation.lng){
                if(annotation.coordinate){
                  annotation['coordinate'] = [annotation['lat'], annotation['lng']]
                }
                delete annotation['lat']
                delete annotation['lng']
              }
            });
          }
        }
      });
    }

    //delete '' translations
    const newPasDefinitonValuesCleanedTranslations = deepCopy(newPasDefinitonValuesCleanedMap)
    if(newPasDefinitonValuesCleanedTranslations.translations ){
      if(this.oldTemplateData.translations){
        const languages = Object.keys(newPasDefinitonValuesCleanedTranslations.translations)
        const translations = deepCopy(newPasDefinitonValuesCleanedTranslations.translations)
        const oldTranslations = deepCopy(this.oldTemplateData.translations)

        languages.forEach ( (language, index) => {
          if(translations[language]){
            const keys = Object.keys(translations[language])
            keys.forEach(key => {
              const idKeyField = key.split('.')
              const id = idKeyField[0]

              if(newPasDefinitonValuesCleanedTranslations.widgets && newPasDefinitonValuesCleanedTranslations.widgets.length!=0){
                if(!newPasDefinitonValuesCleanedTranslations.widgets.find( widget => id.includes(widget.id))){
                  // if(!newPasDefinitonValuesCleanedTranslations.widgets.find( widget => widget.id == id)){
                  // console.log('*********remove translations, deleted widget', language, idKeyField)
                  newPasDefinitonValuesCleanedMap.translations[language][key] = deleteField()
                  delete newPasDefinitonValues.translations[language][key]

                }else{
                  let value = translations[language][key]

                  // if( value == ''){
                  //   if(!oldTranslations[language] || !oldTranslations[language][key] || oldTranslations[language][key]== ''){
                  //     value = undefined
                  //   }
                  // }
                  if(!value && oldTranslations && oldTranslations[language] && oldTranslations[language][key]){
                    // console.log('*********removedValue on  ', language,key )
                    newPasDefinitonValuesCleanedMap.translations[language][key] = deleteField()
                    delete newPasDefinitonValues.translations[language][key]
                  }
                }
              }else{
                newPasDefinitonValuesCleanedMap.translations = deleteField()
                delete newPasDefinitonValues.translations
              }

            })

            if(keys.length == 0){
              // console.log('empty language')
              newPasDefinitonValuesCleanedMap.translations[language] = deleteField()
              delete newPasDefinitonValues.translations[language]
            }
          }
        })

        if( Object.keys(translations).length != 0){
          removeUndefined(translations)
          newPasDefinitonValues.translations == translations
        }else{
          // newPasDefinitonValues.translations == undefined
          // newPasDefinitonValuesCleanedMap.translations = deleteField()
        }
      }
      if(newPasDefinitonValuesCleanedTranslations.translations && newPasDefinitonValuesCleanedTranslations.languages){ // this.oldTemplateData.languages
        const languages = newPasDefinitonValuesCleanedTranslations.languages

        if(JSON.stringify(languages) != JSON.stringify(this.oldTemplateData.languages)){

          const translations = Object.keys(newPasDefinitonValuesCleanedTranslations.translations)
          translations.forEach( lang => {
            if(!languages.includes(lang)){
              newPasDefinitonValuesCleanedMap.translations[lang] = {}
              newPasDefinitonValuesCleanedMap.translations[lang] = deleteField()
              delete newPasDefinitonValues.translations[lang]
            }
          })
        }
        
      }
    }

    this.templateData.setFromAny(newPasDefinitonValues)
    const copia = deepCopy(this.templateData)
    this.changesToSave=false
    const oldTemplate=this.templateData
    this.oldTemplateData=deepCopy(oldTemplate)

    try{
      this.commentService.progressSpin.emit(true)
      //------do not delete this------------
      // if(this.unexistingPass){
        // await this.passDefinitionService.setPassDefinition(this.templateData)
        await this.passDefinitionService.setPassDefinition(newPasDefinitonValuesCleanedMap)
      // }else{
        // await this.passDefinitionService.updatePassDefinition(this.templateData)
        // await this.passDefinitionService.updatePassDefinition(newPasDefinitonValuesCleanedMap)
      // }
      this.commentService.progressSpin.emit(false)
      this.unexistingPass=false
        this.allWidgets= []
        this.imgsToUpBillboardImage=[]
        this.imgsToUpImage=[]
        this.imgsToUpCallToAction=[]
        this.imgsToUpImageBlocks=[]
        this.imgsToUpText=[]
        this.imgsToUpImageHorizontal=[]
        this.updateFonts()
        this.passTemplateLanguages=[]
        this.settingsPass=[]
        this.passTemplateLanguagesO['default']= newPasDefinitonValues
        this.settingsPass.push(copia)
        this.editTemplate()

        const message = this.translate.instant("SnackBarConstants.UPDATE_OK")
        this.commentService.addSnackBar.emit(message)
    } catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.UPDATE_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  async saveEditTemplate(){
    this.uploadService.setImagesUploaded()
    this.uploadService.setFontsUploaded()

    try {
      await this.contentTypeService.updateContentTypeTimestamp(this.contentTypeId);
    } catch (error) {
      console.error('Failed to update Timestamp:', error);
    }

    try{
      this.commentService.progressSpin.emit(true)

      if(this.tridyCount>1){
        this.commentService.progressSpin.emit(false)
        let dialogRef= this.dialog.open(UpdateConfirmationDialogComponent, {
          data:{
            type: 'passTemplate',
            title: 'publishDesign',
            message: 'pass.tridy_update',
            question: 'pass.tridy_update_question',
            tridyCount : this.tridyCount
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result[0]){
            this.commentService.progressSpin.emit(true)
            setTimeout(() =>{
              this.saveEditTemplateValues()
            }, 800);

          }
        });
      }else{
        setTimeout(() =>{
          this.saveEditTemplateValues()
        }, 800);
      }

    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
    }
  }

  returnChangesActive(event: any){
    if(event.newdata){
      const data = event.newdata
    removeUndefinedValuesFromObject(data)
    this.allWidgets.forEach( (w,index) =>{
      if(w.id == data.id /*&& w.type == data.type*/){
        this.allWidgets[index]=data
      }
    })
    }

    if(event.translations){
      this.templateForm.patchValue({translations : event.translations})
    }

    const uploaded = this.uploadService.getImagesUploaded()
    this.updateFormValue()
  }

  returnChangesActiveTranslations(event:any){
    //console.log(event)
    this.translationMode = false

    if(event.state){
      if(event.state == 'add'){
        if(event.translations){
          this.templateForm.patchValue({translations : event.translations})
        }
      }

      if(event.state == 'cancel'){
        this.translationMode = false
      }
    }
   
  }


  viewPassLanguageForm(){
    const copiaForm = deepCopy(this.templateForm.value)
    // this.languageSelected = lang//coment to prevent select to chnage language select 12.07
    let firstLanguage
    let languagesList
    if(this.passLanguages.length!=0){
      languagesList=this.passLanguages
      firstLanguage=this.passFirstLanguage
    }else{
      languagesList=this.contextLanguages
      firstLanguage=this.contextFirstLanguage
    }

    let dialogRef = this.dialog.open(LanguageVisualisationDialogComponent, {
      // panelClass: 'custom-modalbox',
      // maxWidth: '96vw',
      panelClass: 'custom-dialog-container',
      position: { left: '0'},
      animation: {
        to: "right",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      },
      data:{
        values:copiaForm,
        // firstLanguageContexts: this.contextFirstLanguage,
        // contextLanguages: this.contextLanguages,
        firstLanguageContexts: firstLanguage,
        contextLanguages: languagesList,
        //languageSelected: lang,
        idToShow:this.idToShow
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.passLanguages.length!=0){
        this.languageSelected = this.passFirstLanguage   
      }else{
        this.languageSelected = this.contextFirstLanguage   
      }
    })
  }

  async supportUrl(url, type){
    if(url ){
      const incomingURL = new URL(url)
      let hostname = environment.urlHostName
      
      if(incomingURL.protocol=="pass:" ){
        const parts = url.match(/pass:\/\/(.+)\/(.+)/)
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.urlFonts[url]=firebaseUrl
          this.urlFonts[type]=firebaseUrl
        }else{
          const newparts = url.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.urlFonts[url]=firebaseUrl
            this.urlFonts[type]=firebaseUrl
          }
        }
        
      }

      if (incomingURL.protocol === "context:") {
        const matchName = url.match(/context:\/\/fonts\/([^/]+)/);
        if (matchName) {
          const fontName = matchName[1]; 

          if (fontName) {
            try {
              const contextId =  this.settingsService.contextId$
              const firebaseUrl = `https://${hostname}/v1/context/${contextId}/fonts/${fontName}`;

              this.urlFonts[url] = firebaseUrl;
              this.urlFonts[type] = firebaseUrl;
            } catch (error) {
              console.error('Error retrieving context ID', error);
            }
          }
        } else {
          console.error('Font name could not be extracted from the URL.');
        }
      }

      if(incomingURL.protocol =="tridy:"){
        const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
          if(index>0 && index<parts.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`
        this.urlFonts[type]=firebaseUrl
      }

      if(incomingURL.protocol == "https:" && incomingURL.host == "firebasestorage.googleapis.com"){
        const parts = url.match(/https:\/\/firebasestorage.googleapis.com(.+)/)
        const host = incomingURL.pathname.match(/narravero-eu-dev/)
        const passes = incomingURL.pathname.match(/(.+)passes(.+)/)
        const info = passes[2].split("%2F")
        const passTemplateID = info[1]
        const path = info[2]
        const name = decodeURI(info[2])
        const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        this.urlFonts[url]=firebaseUrl
        this.urlFonts[type]=firebaseUrl

      }
      this.updateFontsType(type)
    }
  }

  updateFontsType(type){
    this.commentService.progressSpin.emit(true)
    if(this.themeData && this.themeData['fonts']){
      if(type=='title'){
        if(this.themeData['fonts'].title && this.themeData['fonts'].title.name && this.themeData['fonts'].title.url ){  
          if(this.urlFonts[this.themeData['fonts'].title.url] || this.urlFonts[type]){
            document.documentElement.style.setProperty('--titleFontName',this.themeData['fonts'].title.name)
            document.documentElement.style.setProperty('--titleFontUrl',this.urlFonts['title'])
            document.documentElement.style.setProperty('--titleFontUrlsrc',"url("+this.urlFonts['title']+")")
    
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].title.name  + ";\
                src: url('" + this.urlFonts['title']  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
            
            if(document.getElementById('title')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--titleFontName')
              document.getElementById('title').style.fontFamily = vart
              injectGlobal`
                #title {
                  line-height: 110%;
                  font-family:var(--titleFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'titleFontName'};
                src: var(--titleFontUrlsrc);
              }
              #title {
                line-height: 110%;
                font-family: 'titleFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
    
            
          }else{
            document.documentElement.style.setProperty('--titleFontName',this.themeData['fonts'].title.name)
            document.documentElement.style.setProperty('--titleFontUrl',this.themeData['fonts'].title.url)
            document.documentElement.style.setProperty('--titleFontUrlsrc',"url("+this.themeData['fonts'].title.url+")")
            
            if(document.getElementById('title')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--titleFontName')
              document.getElementById('title').style.fontFamily = vart
              injectGlobal`
                #title {
                  line-height: 110%;
                  font-family: var(--titleFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'titleFontName'};
                src: var(--titleFontUrlsrc);
              }
              #title {
                line-height: 110%;
                font-family: 'titleFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].title.name  + ";\
                src: url('" + this.themeData['fonts'].title.url  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
          }
        }
        else{
          if(document.getElementById('title'))
          document.getElementById('title').style.fontFamily = "Roboto"
          injectGlobal`
            #title {
              font-family: 'Roboto', sans-serif; 
            }
          `
          // title
          document.documentElement.style.setProperty('--titleFontName',"Roboto")
          document.documentElement.style.setProperty('--titleFontUrl',"")
          document.documentElement.style.setProperty('--titleFontUrlsrc',"")
        }
      }

      if(type=='subtitle'){
        if(this.themeData['fonts'].subtitle && this.themeData['fonts'].subtitle.name && this.themeData['fonts'].subtitle.url ){  
          if(this.urlFonts[this.themeData['fonts'].subtitle.url] || this.urlFonts[type]){
            document.documentElement.style.setProperty('--subtitleFontName',this.themeData['fonts'].subtitle.name)
            document.documentElement.style.setProperty('--subtitleFontUrl',this.urlFonts['subtitle'])
            document.documentElement.style.setProperty('--subtitleFontUrlsrc',"url("+this.urlFonts['subtitle']+")")
    
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].subtitle.name  + ";\
                src: url('" + this.urlFonts['subtitle']  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
            
            if(document.getElementById('subtitle')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--subtitleFontName')
              document.getElementById('subtitle').style.fontFamily = vart
              injectGlobal`
                #subtitle {
                  line-height: 110%;
                  font-family: var(--subtitleFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'subtitleFontName'};
                src: var(--subtitleFontUrlsrc);
              }
              #subtitle {
                line-height: 110%;
                font-family: 'subtitleFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
            
          }else{
            document.documentElement.style.setProperty('--subtitleFontName',this.themeData['fonts'].subtitle.name)
            document.documentElement.style.setProperty('--subtitleFontUrl',this.themeData['fonts'].subtitle.url)
            document.documentElement.style.setProperty('--subtitleFontUrlsrc',"url("+this.themeData['fonts'].subtitle.url+")")
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("@import url('"+this.themeData['fonts'].subtitle.url+"');"));
            document.body.appendChild(newStyle)
            
            if(document.getElementById('subtitle')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--subtitleFontName')
              document.getElementById('subtitle').style.fontFamily = vart
              injectGlobal`
                #subtitle {
                  line-height: 110%;
                  font-family: var(--subtitleFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'subtitleFontName'};
                src: var(--subtitleFontUrlsrc);
              }
              #subtitle {
                line-height: 110%;
                font-family: 'subtitleFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].subtitle.name  + ";\
                src: url('" + this.themeData['fonts'].subtitle.url  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
          }
        }
        else{
          if(document.getElementById('subtitle'))
          document.getElementById('subtitle').style.fontFamily = "Roboto"
          injectGlobal`
            #subtitle {
              line-height: 110%;
              font-family: 'Roboto', sans-serif; 
            }
          `
          // subtitle
          document.documentElement.style.setProperty('--subtitleFontName',"Roboto")
          document.documentElement.style.setProperty('--subtitleFontUrl',"")
          document.documentElement.style.setProperty('--subtitleFontUrlsrc',"")
        }
      }

      if(type=='plain'){
        if(this.themeData['fonts'].plain && this.themeData['fonts'].plain.name && this.themeData['fonts'].plain.url ){  
          if(this.urlFonts[this.themeData['fonts'].plain.url] || this.urlFonts[type]){
            document.documentElement.style.setProperty('--plainFontName',this.themeData['fonts'].plain.name)
            document.documentElement.style.setProperty('--plainFontUrl',this.urlFonts['plain'])
            document.documentElement.style.setProperty('--plainFontUrlsrc',"url("+this.urlFonts['plain']+")")
    
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].plain.name  + ";\
                src: url('" + this.urlFonts['plain']  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
            
            if(document.getElementById('plain')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--plainFontName')
              document.getElementById('plain').style.fontFamily = vart
              injectGlobal`
                #plain {
                  line-height: 110%;
                  font-family: var(--plainFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'plainFontName'};
                src: var(--plainFontUrlsrc);
              }
              #plain {
                line-height: 110%;
                font-family: 'plainFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
    
            
          }else{
            document.documentElement.style.setProperty('--plainFontName',this.themeData['fonts'].plain.name)
            document.documentElement.style.setProperty('--plainFontUrl',this.themeData['fonts'].plain.url)
            document.documentElement.style.setProperty('--plainFontUrlsrc',"url("+this.themeData['fonts'].plain.url+")")
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("@import url('"+this.themeData['fonts'].plain.url+"');"));
            document.body.appendChild(newStyle)
            
            if(document.getElementById('plain')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--plainFontName')
              document.getElementById('plain').style.fontFamily = vart
              injectGlobal`
                #plain {
                  line-height: 110%;
                  font-family: var(--plainFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'plainFontName'};
                src: var(--plainFontUrlsrc);
              }
              #plain {
                line-height: 110%;
                font-family: 'plainFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].plain.name  + ";\
                src: url('" + this.themeData['fonts'].plain.url  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
          }
        }
        else{
          if(document.getElementById('plain'))
          document.getElementById('plain').style.fontFamily = "'Roboto', sans-serif"
          injectGlobal`
            #plain {
              line-height: 110%;
              font-family: 'Roboto', sans-serif; 
            }
          `
          // plain
          document.documentElement.style.setProperty('--plainFontName', "'Roboto', sans-serif");
          document.documentElement.style.setProperty('--plainFontUrl',"")
          document.documentElement.style.setProperty('--plainFontUrlsrc',"")
        }
      }

      if(type=='bold'){
        if(this.themeData['fonts'].bold && this.themeData['fonts'].bold.name && this.themeData['fonts'].bold.url ){  
          if(this.urlFonts[this.themeData['fonts'].bold.url] || this.urlFonts[type]){
            document.documentElement.style.setProperty('--boldFontName',this.themeData['fonts'].bold.name)
            document.documentElement.style.setProperty('--boldFontUrl',this.urlFonts['bold'])
            document.documentElement.style.setProperty('--boldFontUrlsrc',"url("+this.urlFonts['bold']+")")
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].bold.name  + ";\
                src: url('" + this.urlFonts['bold']  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
            
            if(document.getElementById('bold')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--boldFontName')
              document.getElementById('bold').style.fontFamily = vart
              injectGlobal`
                #bold {
                  line-height: 110%;
                  font-family:var(--boldFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'boldFontName'};
                src: var(--boldFontUrlsrc);
              }
              #bold {
                line-height: 110%;
                font-family: 'boldFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
    
          }else{
            document.documentElement.style.setProperty('--boldFontName',this.themeData['fonts'].bold.name)
            document.documentElement.style.setProperty('--boldFontUrl',this.themeData['fonts'].bold.url)
            document.documentElement.style.setProperty('--boldFontUrlsrc',"url("+this.themeData['fonts'].bold.url+")")
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("@import url('"+this.themeData['fonts'].bold.url+"');"));
            document.body.appendChild(newStyle)
            
            if(document.getElementById('bold')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--boldFontName')
              document.getElementById('bold').style.fontFamily = vart
              injectGlobal`
                #bold {
                  line-height: 110%;
                  font-family: var(--boldFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'boldFontName'};
                src: var(--boldFontUrlsrc);
              }
              #bold {
                line-height: 110%;
                font-family: 'boldFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].bold.name  + ";\
                src: url('" + this.themeData['fonts'].bold.url  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
          }
        }
        else{
          if(document.getElementById('bold'))
          document.getElementById('bold').style.fontFamily = "'Roboto', sans-serif"
          injectGlobal`
            #bold {
              line-height: 110%;
              font-family: 'Roboto', sans-serif; 
            }
          `
          // bold
          document.documentElement.style.setProperty('--boldFontName',"'Roboto', sans-serif")
          document.documentElement.style.setProperty('--boldFontUrl',"")
          document.documentElement.style.setProperty('--boldFontUrlsrc',"")
        }
      }

      if(type=='italic'){
        if(this.themeData['fonts'].italic && this.themeData['fonts'].italic.name && this.themeData['fonts'].italic.url ){  
          if(this.urlFonts[this.themeData['fonts'].italic.url] || this.urlFonts[type]){
            document.documentElement.style.setProperty('--italicFontName',this.themeData['fonts'].italic.name)
            document.documentElement.style.setProperty('--italicFontUrl',this.urlFonts['italic'])
            document.documentElement.style.setProperty('--italicFontUrlsrc',"url("+this.urlFonts['italic']+")")
    
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].italic.name  + ";\
                src: url('" + this.urlFonts['italic']  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
            
            if(document.getElementById('italic')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--italicFontName')
              document.getElementById('italic').style.fontFamily = vart
              injectGlobal`
                #italic {
                  line-height: 110%;
                  font-family: var(--italicFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'italicFontName'};
                src: var(--italicFontUrlsrc);
              }
              #italic {
                line-height: 110%;
                font-family: 'italicFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
            
          }else{
            document.documentElement.style.setProperty('--italicFontName',this.themeData['fonts'].italic.name)
            document.documentElement.style.setProperty('--italicFontUrl',this.themeData['fonts'].italic.url)
            document.documentElement.style.setProperty('--italicFontUrlsrc',"url("+this.themeData['fonts'].italic.url+")")
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("@import url('"+this.themeData['fonts'].italic.url+"');"));
            document.body.appendChild(newStyle)
            
            if(document.getElementById('italic')){
              const vart = getComputedStyle(document.documentElement).getPropertyValue('--italicFontName')
              document.getElementById('italic').style.fontFamily = vart
              injectGlobal`
                #italic {
                  line-height: 110%;
                  font-family: var(--italicFontName); 
                }
              `
            }else{
              var sheet = document.createElement('style')
              sheet.innerHTML = ` @font-face {
                font-family: 'italicFontName'};
                src: var(--italicFontUrlsrc);
              }
              #italic {
                line-height: 110%;
                font-family: 'italicFontName'; 
              }`
              document.body.appendChild(sheet);
            }        
  
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + this.themeData['fonts'].italic.name  + ";\
                src: url('" + this.themeData['fonts'].italic.url  + "');\
            }\
            "));
            document.head.appendChild(newStyle);
          }
        }
        else{
          if(document.getElementById('italic'))
          document.getElementById('italic').style.fontFamily = "Roboto"
          injectGlobal`
            #italic {
              line-height: 110%;
              font-family: 'Roboto', sans-serif; 
            }
          `
          // italic
          document.documentElement.style.setProperty('--italicFontName',"Roboto")
          document.documentElement.style.setProperty('--italicFontUrl',"")
          document.documentElement.style.setProperty('--italicFontUrlsrc',"")
        }
      }
    this.commentService.progressSpin.emit(false)
    }
  }

  countTridysPass(){
    const data  = {
      contentTypeId: this.contentTypeId,
      contextId: this.settingsService.contextId$
    }

    this.commentService.progressSpin.emit(true)
    const countTridys = httpsCallable(this.functions, 'info-countTridys');

    countTridys( data ).then((result) => {
      const data = result.data;
      this.tridyCount = data['count']
      this.commentService.progressSpin.emit(false)
    })
    .catch((error) => {
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
      this.commentService.addSnackBar.emit(message)
      this.commentService.addSnackBar.emit(error)
    });
  }

  showTranslatedView(){
    if(this.passLanguages && this.passLanguages.length>1){
      this.translationMode = true
      this.translationDisabled = false
    }else{
      if(!this.passLanguages || this.passLanguages.length ==0 ){
        if(this.contextLanguages && this.contextLanguages.length>1)
          this.translationMode = true
        
        if(!this.contextLanguages){
          this.translationDisabled = true
          const message = this.translate.instant("SnackBarConstants.NO_GLOBAL_LANGUAGES")
          this.commentService.addSnackBar.emit(message)
        }
        if(this.contextLanguages && this.contextLanguages.length==1){
          this.translationDisabled = true
          const message = this.translate.instant("SnackBarConstants.MORE_GLOBAL_LANGUAGES")
          this.commentService.addSnackBar.emit(message)
        }
      }

      if(this.passLanguages && this.passLanguages.length==1){
        this.translationDisabled = true
        const message = this.translate.instant("SnackBarConstants.MORE_LANGUAGES")
        this.commentService.addSnackBar.emit(message)
      }

      if(this.passLanguages && this.passLanguages.length==0 && this.contextLanguages && this.contextLanguages.length ==0 ){
        this.translationDisabled = true
        const message = this.translate.instant("SnackBarConstants.MORE_LANGUAGES")
        this.commentService.addSnackBar.emit(message)
      }

    }
  }

  closeTranslatedView(){
    this.translationMode = false
  }
}


