import { Injectable } from '@angular/core';
import { getAuth, user } from '@angular/fire/auth';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TemplatesService } from './templates.service';
import { NetworkConstants } from 'src/network.constants';
import { ContentType } from '../../../../backend/src/contentType.dto';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommentsService } from './comments.service';
import { defaultRoles, defaultRolesRights, userRolesRightsAll } from 'src/roles';
import { Template } from '../../../../backend/src/template.dto';
import { deepCopy } from '../../../../backend/utils/object';
import { NavbarAccesService } from './navbar-acces.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  allContextsData = []
  uid$:any
  currentUser$:any
  allUsers$:any
  settingsUserData$:any
  contextId$:any
  preReloadContextId$:any
  contextIds$:any[]
  userId$:any[]
  contextIdFields$:any=[]
  SortedcontextIdFields$ = {} 
  usersWithContextId$:any=[]
  changeAccount=false
  userAccount$:any
  contextAccount$:any

  allContexts$:any=[]
  allAnalytics$:any=[]
  allTridys$:any=[]
  allTridysReport$:any=[]
  allReports$:any=[]
  fieldContexts$:any=[]
  languagesContexts$:any=[]
  firstLanguageContexts$:any

  settingsAccount:any
  settingsAnalytics:any=[]
  settingsAdvancedExperiences:any=[]
  settingsAdvancedExperiencesNFC:any=[]
  settingsAdvancedExperiencesAppStoreId:any=[]
  settingsAPItokens:any=[]
  settingsContent:any=[]
  settingsInterfaces:any=[]
  settingsContentTypes:any=[]
  settingsContentTypeId:any=[]
  settingsContentTypeIdSelected:any=[]
  settingsContentTypesWithId:any=[]
  settingsCustomers:any=[]
  settingsFinishedTasks:any=[]
  settingsItems:any=[]
  settingsIdentifiers:any=[]
  settingsMedia:any
  settingsPassDefinitions:any=[]
  settingsPassDefinitionsWithId:any=[]
  settingsPassDefinitionId: Template
  settingsProducts:any
  settingsPurchases:any=[]
  settingsTaskTemplates:any
  settingsTridys:any
  settingsUsers:any
  settingsWorkTaskTemplates:any=[]
  settingsWorkTaskTemplatesGroups:any=[]
  settingsWorkTaskTemplatesGroupsSize=0
  settingsWorkTasks:any=[]
  paginator$:any  = [15,25,50,100]
  userRoleBetaWidget = false
  settingsPassId:any
  userRoles$:any=[]
  userRolesRights$:any={}
  contextModules$:any={}
  defaultRoles = defaultRolesRights
  entities= ['context', 'contentTypes', 'passTemplates', 'content','media', 'workTaskTemplates', 'workTask', 
    'products', 'tridys', 'users', 'reports','interfaces']

  contextIdChange$:Subject<string> = new Subject<string>();
  selectedContextDataChange$:Subject<any> = new Subject<any>();
  userNameChange$:Subject<string> = new Subject<string>();
  userDataChange$:Subject<string> = new Subject<string>();

mapToObserve = new Map([
  [NetworkConstants.COLLECTION_ANALYTICS, this.settingsAnalytics],
  [NetworkConstants.COLLECTION_APITOKENS, this.settingsAPItokens],
  [NetworkConstants.COLLECTION_CONTENT, this.settingsContent],
  [NetworkConstants.COLLECTION_CONTENT_TYPES, this.settingsContentTypes],
  ["ContentTypesId",this.settingsContentTypesWithId],
  [NetworkConstants.COLLECTION_CUSTOMERS, this.settingsCustomers],
  [NetworkConstants.COLLECTION_IDENTIFIERS, this.settingsIdentifiers],
  [NetworkConstants.COLLECTION_ITEMS, this.settingsItems],
  [NetworkConstants.COLLECTION_PASSDEFINITIONS, this.settingsPassDefinitions],
  ["PassDefinitionsId", this.settingsPassDefinitionsWithId],
  [NetworkConstants.COLLECTION_PURCHASES, this.settingsPurchases],
  [NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES, this.settingsWorkTaskTemplates],
  [NetworkConstants.COLLECTION_WORK_TASKS, this.settingsWorkTasks]
])

  constructor(private firestore: AngularFirestore,
    private datePipe: DatePipe,
    private auth: AuthService,
    private templateService: TemplatesService,
    private router: Router,
    private commentsService: CommentsService,
    private navbarAccesService: NavbarAccesService

    ) { }

  getDataUsersFirestore(){
    return this.firestore.collection(NetworkConstants.COLLECTION_USERS).snapshotChanges();
  }


  getDataPassDefinitionsFirestore(contextId){
    return this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
    .doc(contextId).collection(NetworkConstants.COLLECTION_PASSDEFINITIONS)
    .snapshotChanges().subscribe(
      (pd:any)=> {
        var list=[]
        pd.map( (e:any)=> {
          const data= e.payload.doc.data()
          list.push(data)
        });
        return list;
      }

    );
  }

  observeContextsFirestore(){
    const a = this.firestore.collection('Contexts')
    .snapshotChanges();

    a.subscribe(res => {
      res.map( (e:any) => {
        const data = e.payload.doc.data();
        this.allContexts$.push(data)
        return data;
      })
    })
 
    return this.allContexts$
  }

  getCoverImagesWithNFCPrefix(nfcPRefix){
    this.settingsAdvancedExperiencesNFC = []

    const a = new Promise<any>((resolve)=> {

      const wt= this.firestore
      .collection(NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES,ref => ref.where("contextId", "==", this.contextId$))
      .snapshotChanges();

      wt.subscribe( res => {
        resolve(res)
        
      })
    })

      a.then( (worktasks) => {
        worktasks.map( (tasks) => {
          const data = tasks.payload.doc.data();
          data.id = tasks.payload.doc.id;

          if(data.urlPrefix == nfcPRefix){
            if(data['appStoreImageURL']){
              const w="282"
              const h="171"
              const c="bb"
              const f="png"
              const appStoreImageURL = data['appStoreImageURL']
              data['imageUrl'] = decodeURI(appStoreImageURL).replaceAll("{w}", w).replaceAll("{h}", h).replaceAll("{c}", c).replaceAll("{f}", f)
            }
            
            this.settingsAdvancedExperiencesNFC.push(data)
          }
            
        })
      })
    
    return this.settingsAdvancedExperiencesNFC
  }

  updateUser(userId: string, updatedData: any): Promise<void> {
    const dataToUpdate = this.removeUndefinedUserValues(updatedData);
    
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.firestore.collection(NetworkConstants.COLLECTION_USERS)
          .doc(userId)
          .update(dataToUpdate);
   
        resolve();
      } catch (error) {
        console.error('Error updating user:', error);
        reject(error);
      }
    });
  }

  private removeUndefinedUserValues(obj: any): any {
    const newObj: any = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  }

  getCoverImagesWithAppStoreId(appStoreId){
    this.settingsAdvancedExperiencesAppStoreId = []

    const a = new Promise<any>((resolve)=> {

      const wt= this.firestore
      .collection(NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES,ref => ref.where("appStoreId", "==", appStoreId))
      .snapshotChanges();

      wt.subscribe( res => {
        resolve(res)
        
      })
    })

      a.then( (worktasks) => {
        worktasks.map( (tasks) => {
          const data = tasks.payload.doc.data();
          data.id = tasks.payload.doc.id;

          if(data.contextId == this.contextId$){
            if(data['appStoreImageURL']){
              const w="282"
              const h="171"
              const c="bb"
              const f="png"
              const appStoreImageURL = data['appStoreImageURL']
              data['imageUrl'] = decodeURI(appStoreImageURL).replaceAll("{w}", w).replaceAll("{h}", h).replaceAll("{c}", c).replaceAll("{f}", f)
            }
            
            this.settingsAdvancedExperiencesAppStoreId.push(data)
          }
            
        })
      })
    
    return this.settingsAdvancedExperiencesAppStoreId
  }

  observeAdvancedExperiencesFirestore(){
    this.settingsAdvancedExperiences = []

    const a = new Promise<any>((resolve)=> {

      const wt= this.firestore
      .collection(NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES,ref => ref.where("contextId", "==", this.contextId$))
      .snapshotChanges();

      wt.subscribe( res => {
        resolve(res)
        
      })
    })

      a.then( (worktasks) => {
        worktasks.map( (tasks) => {
          const data = tasks.payload.doc.data();
          data.id = tasks.payload.doc.id;
            if(data['appStoreImageURL']){
              const w="282"
              const h="171"
              const c="bb"
              const f="png"
              const appStoreImageURL = data['appStoreImageURL']
              data['imageUrl'] = decodeURI(appStoreImageURL).replaceAll("{w}", w).replaceAll("{h}", h).replaceAll("{c}", c).replaceAll("{f}", f)
            }
            
            this.settingsAdvancedExperiences.push(data)
        })
      })
      
    
    return this.settingsAdvancedExperiences
}

  observeAdvancedExperiencesIdFirestore(ae_id:string){
    return  new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES,ref => ref.where("contextId", "==", this.contextId$))
      .doc(ae_id).valueChanges()
      .subscribe(ae => {
        resolve(ae)});
    })
  }

  observeReportsFirestore(){
    this.allReports$ = []

    const prom = new Promise<any>((resolve)=> {

      const a = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(this.contextId$).collection(NetworkConstants.COLLECTION_REPORTS)
      .snapshotChanges();

      a.subscribe(res => {
        resolve(res)
      })
    });

    prom.then( value=>{
      value.map( (e:any) => {
        const data = e.payload.doc.data();
        data.id = e.payload.doc.id;
          this.allReports$.push(data) 
      })
    } )


    return this.allReports$
  }

  observeTridysOfReportFirestore(contentTypeId:any){
    this.allTridysReport$ = []

    const prom = new Promise<any>((resolve)=> {
      const a = this.firestore.collection(NetworkConstants.COLLECTION_TRIDYS,ref => ref.where("contextId", "==", this.contextId$))
      .snapshotChanges();

      a.subscribe(res => {
        resolve(res)
      })
    });

    prom.then( value=>{
      value.map( (e:any) => {
        const data = e.payload.doc.data();
        data.id = e.payload.doc.id;
        if(data.contentType == contentTypeId){
          this.allTridysReport$.push(data)
        } 
      })
    })


    return this.allTridysReport$
  }

  observeTridysFirestore(){
    this.allTridys$ = []

    const prom = new Promise<any>((resolve)=> {

      const a = this.firestore.collection(NetworkConstants.COLLECTION_TRIDYS,ref => ref.where("contextId", "==", this.contextId$))
      .snapshotChanges();

      a.subscribe(res => {
        resolve(res)
      })
    });

    prom.then( value=>{
      value.map( (e:any) => {
        const data = e.payload.doc.data();
        data.id = e.payload.doc.id;
        if(data.contextId ==this.contextId$){
          this.allTridys$.push(data)

          if(data.contentType){
            if(data.contentType!='order' && data.contentType != 'item' && data.contentType!='Event'){
              const a = this.observeTridyContentTypeName(data.contentType)
              a.then( name =>{
                data['contentName']=name
              })
            }
            
          }
        } 
      })
    } )

    return this.allTridys$
  }

  observeTridyContentTypeName(contentTypeId){
    let name=[]
    const a = new Promise<string>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES)
      .doc(contentTypeId)
      .valueChanges().subscribe(
        res => {
          const data = res
          if(data){
            if(data['displayName']){
              name.push(data['displayName'])
              resolve(data['displayName'])
            }
          }
          

        }
      )
    })
   
    return a
  }

  observeTridysIdFirestore(tridyId:string){
    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_TRIDYS)
      .doc(tridyId).valueChanges()
      .subscribe(tridy => {
        resolve(tridy)});
    })

    return a
  }


  observeTridysIdFinishedTasksFirestore(tridyId: string) {
    let finishedTasks: any[] = [];
  
    return new Promise<any[]>((resolve, reject) => {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.contextId$).collection(NetworkConstants.COLLECTION_WORK_TASKS, ref => ref.where('tridyId', '==', tridyId))
        .snapshotChanges()
        .subscribe(
          tridy => {

            finishedTasks = [];
  
            tridy.forEach((e: any) => {
              const data = e.payload.doc.data();
              data.id = e.payload.doc.id;
              finishedTasks.push(data);
            });
  
            // Sort tasks by timeStamp in descending order (latest first)
            finishedTasks.sort((a, b) => {
              const timeA = a.timeStamp ? this.timestampToMillis(a.timeStamp) : 0;
              const timeB = b.timeStamp ? this.timestampToMillis(b.timeStamp) : 0;
              return timeB - timeA; // Descending order
            });
  
            resolve(finishedTasks);
          },
          error => {
            reject(error);
          }
        );
    });
  }
  
  // Convert Timestamp to milliseconds
  timestampToMillis(timestamp: any): number {
    if (timestamp && timestamp.seconds) {
      return timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000;
    }
    return 0;
  }
  
  observeContextsIdLoggedFirestore(contextId){
    this.fieldContexts$.splice(0)

    const a = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
    .doc(contextId)
    .valueChanges();

    a.subscribe(res => {
      let data = [] 
      data.push(res)
      if(this.fieldContexts$.length<1){
        this.fieldContexts$.push(data[0])
    
      }
      if(res['languages']){
        this.languagesContexts$ = res['languages']
        this.firstLanguageContexts$ = this.languagesContexts$[0]
      }else{
        this.languagesContexts$ = []
        this.firstLanguageContexts$ = ''
      }
    })
    return this.fieldContexts$
  }

  getPassDefinitionId(id) {
    let passToShow =[]
    
    const passId = new Promise<any>((resolve, reject)=> {
      this.firestore.collection('Contexts')
      .doc(this.contextId$)
      .collection('PassDefinitions')
      .doc(id).valueChanges()
      .subscribe(pass => {
        if(pass){
          pass['id'] = id
          this.settingsPassDefinitionId = new Template()
          this.settingsPassDefinitionId.setFromAny(pass)
          resolve(pass)
        }else{
          resolve(false)
        }
        
      },
      
      
      err=> {
        this.commentsService.progressSpin.emit(false)
        resolve(err)


      })
    })

    return passId
  }


  getPassDefinitions(contextId){
    const passdef =new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(contextId)
      .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS)
      .valueChanges()
      .subscribe( pass=>  resolve(pass) );
    })
      this.settingsPassDefinitions=[]
      passdef.then((value)=>{
        this.settingsPassDefinitions.push(value)
      })
      return passdef
  }

  observeContextsPassDefinitionsFirestoreWithId(contextId:string){
    this.settingsPassDefinitionsWithId =[]
    this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(contextId)
    .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS)
    .snapshotChanges().subscribe(
      res => {
        res.map( (content:any)=>{
          const data = content.payload.doc.data()
          data.id = content.payload.doc.id;
          this.settingsPassDefinitionsWithId.push(data)
        })
      }
    )
    return this.settingsPassDefinitionsWithId
  }

  getAllContent() {///----not used in elements table
    return new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT).valueChanges()
      .subscribe(content => {
        resolve(content)});
    })
    // this.settingsContent = []
    // this.settingsContent.push(a)

    // return a
  }

  getContentEntries(): Observable<any[]> {///-------contentlist with id +  preformated dates
    return this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
    .doc(this.contextId$)
    .collection(NetworkConstants.COLLECTION_CONTENT)
      .snapshotChanges()
      .pipe(
        map(contenttypes => {
          return contenttypes.map(types => {
            const data = deepCopy(types.payload.doc.data());
            data.id = types.payload.doc.id;
            if (data.creationDate && data.creationDate.seconds) {
              const creationDateTimestamp = new Date(data.creationDate.seconds * 1000);
              data.creationDate = creationDateTimestamp.toISOString();
            }
            if (data.timeStamp && data.timeStamp.seconds) {
              const timeStampTimestamp = new Date(data.timeStamp.seconds * 1000);
              data.timeStamp = timeStampTimestamp.toISOString();
            }
             return data;
          });
        })
      );
  }

  observeContextsContentTypesFirestore(contextId:string){

    this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(contextId)
    .collection(NetworkConstants.COLLECTION_CONTENT_TYPES)
    .valueChanges().subscribe(
      res => {
        res.forEach(r => {
          this.settingsContentTypes.push(r)
        })
      }
    )
    return this.settingsContentTypes
  }

  observeContextsContentTypesIdFirestore(contentTypeId:string){// ----------contentypes/id 
    this.settingsContentTypeIdSelected = []

    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES)
      .doc(contentTypeId).valueChanges()
      .subscribe(content => {
      if(!this.settingsContentTypeIdSelected.includes(content))
        this.settingsContentTypeIdSelected.push(content)
        resolve(content)});
    })

    return a
  }

  observeContextsContentTypesFirestoreWithId(contextId:string){ // ----------contentxpes list + id
    this.settingsContentTypesWithId = []
    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(contextId)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES,ref => ref.orderBy("displayName", "asc"))
      .snapshotChanges()
      .subscribe(
        res => {
          resolve(res)
        }
      )
    })

    a.then( (contents)=>{
      contents.map( (content)=>{
        const data = content.payload.doc.data()
        data.id = content.payload.doc.id;
        this.settingsContentTypesWithId.push(data)
      })
    })

    return this.settingsContentTypesWithId
  }

  getAllContenttypes() { ///-------contentTypes list with id + cover image data
    this.settingsContentTypes = []
    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES, ref => ref.orderBy("displayName", "asc")).snapshotChanges()
      .subscribe(contenttypes => {
        resolve(contenttypes)
      });
    });

    a.then( (ctypes)=>{
      ctypes.map( async (types)=>{
        const data = deepCopy(types.payload.doc.data())
        data.id = types.payload.doc.id
        if(data.advancedExperienceId){
          const cover = this.settingsAdvancedExperiences.find( cover => cover.id === data.advancedExperienceId)
          data['coverImage'] = cover.imageUrl
        }
        this.settingsContentTypes.push(data)
      })
    })

    return this.settingsContentTypes
  }

  getPreformatedContentTypes(): Observable<any[]> {///-------contentTypes list with id + cover image data + preformated dates
    return this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES, ref => ref.orderBy("displayName", "asc"))
      .snapshotChanges()
      .pipe(
        map(contenttypes => {
          return contenttypes.map(types => {
            const data = deepCopy(types.payload.doc.data());
            data.id = types.payload.doc.id;
            if (data.creationDate && data.creationDate.seconds) {
              const creationDateTimestamp = new Date(data.creationDate.seconds * 1000);
              data.creationDate = creationDateTimestamp.toISOString();
            }
            if (data.timeStamp && data.timeStamp.seconds) {
              const timeStampTimestamp = new Date(data.timeStamp.seconds * 1000);
              data.timeStamp = timeStampTimestamp.toISOString();
            }
            if (data.advancedExperienceId) {
              const cover = this.settingsAdvancedExperiences.find(cover => cover.id === data.advancedExperienceId);
              data['coverImage'] = cover ? cover.imageUrl : null;
            }
            return data;
          });
        })
      );
  }

  getAllInterfaces() {
    this.settingsInterfaces = []
    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_INTERFACES).snapshotChanges()
      .subscribe(interf => {
        resolve(interf)
      });
    });

    a.then( (interfaces)=>{
      interfaces.map( (interfa)=>{
        const data = interfa.payload.doc.data()
        data.id = interfa.payload.doc.id
        this.settingsInterfaces.push(data)
      })
    })

    return this.settingsInterfaces
  }


  observeContextsItemsFirestore(){
    this.settingsItems = []

    const i = new Promise<any>((resolve)=> {

      this.firestore
      .collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$).collection(NetworkConstants.COLLECTION_ITEMS)
      .valueChanges().subscribe(
        res => {
          resolve(res)
          res.forEach(r =>{
          })
        }
      )
    })
    i.then( res => {
      res.map( product => {
        this.settingsItems.push(product)
      })

    })
    return this.settingsItems
  }


  observeContextsItemsIdFirestore(itemId:string):Promise<any>{
    let item =[]
    const i = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_ITEMS)
      .doc(itemId)
      .valueChanges()
      .subscribe(product => resolve(product));
    })
    return i

  }


  observeContextsPurchasesFirestore(contextId:string){
    this.firestore
    .collection(NetworkConstants.COLLECTION_CONTEXTS)
    .doc(contextId).collection(NetworkConstants.COLLECTION_PURCHASES)
    .valueChanges().subscribe(
      res => {
        res.forEach(r =>{
          this.settingsPurchases.push(r)
        })
      }
    )
    return this.settingsPurchases
  }

  observeContextsWorkTaskTemplatesFirestore(contextId:string){
    this.firestore
    .collection(NetworkConstants.COLLECTION_CONTEXTS)
    .doc(contextId).collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES)
    .valueChanges().subscribe(
      res => {
        res.forEach(r =>{
          this.settingsWorkTaskTemplates.push(r)
        })
      }
    )
    return this.settingsWorkTaskTemplates
  }


  //called to get data from one work tasks with id
  observeContextsWorkTasksFirestore(workTaskId:string){
    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_WORK_TASKS)
      .doc(workTaskId).valueChanges()
      .subscribe(content => {
        resolve(content)});
    })
    this.settingsWorkTasks = []
    this.settingsWorkTasks.push(a)
    return a
  }

  getWorkTask(){
    return this.settingsWorkTasks[0]
  }

/*   observeContextsWorkTasksFirestoreWithId(){
    this.settingsFinishedTasks = []

    const wt= this.firestore
    .collection(NetworkConstants.COLLECTION_CONTEXTS)
    .doc(this.contextId$).collection(NetworkConstants.COLLECTION_WORK_TASKS)
    .snapshotChanges();

      wt.subscribe( res => {
        res.map((e:any) => {
          const data = e.payload.doc.data();
          data.id = e.payload.doc.id;
          this.settingsFinishedTasks.push(data)
        })
        
      })
      return this.settingsFinishedTasks
  }
 */

  observeContextsWorkTasksFirestoreWithId(): Observable<any[]> {
    return this.firestore
      .collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_WORK_TASKS)
      .snapshotChanges()
      .pipe(
        map(actions => 
          actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }
  

  observeContextsWorkTasksTemplatesFirestore(workTaskTemplateId:string){
    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES)
      .doc(workTaskTemplateId).valueChanges()
      .subscribe(content => {
        resolve(content)});
    })
    this.settingsTaskTemplates = []
    this.settingsTaskTemplates.push(a)

    return a
  }


  observeContextsWorkTasksTemplatesFirestoreWithId(){
    this.settingsWorkTaskTemplates = []

    const a = new Promise<any>((resolve)=> {
      const wt= this.firestore
      .collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$).collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES, ref => ref.orderBy("title", "asc"))
      .snapshotChanges();

      wt.subscribe( res => {
        resolve(res)

        res.map((e:any) => {
          const data = e.payload.doc.data();
          data.id = e.payload.doc.id;
        })
      })
        
    });

    a.then( (worktasks) => {
      worktasks.map( (tasks) => {
        const data = tasks.payload.doc.data();
          data.id = tasks.payload.doc.id;
          this.settingsWorkTaskTemplates.push(data)
      })
    })
      return this.settingsWorkTaskTemplates
  }

  observeContextsWorkTasksTemplatesFirestoreWithContentTypeId(contentTypeId: string) {
    this.settingsWorkTaskTemplates = [];
  
    const a = new Promise<any>((resolve) => {
      const wt = this.firestore
        .collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.contextId$)
        .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES, ref => ref.orderBy("title", "asc"))
        .snapshotChanges();
  
      wt.subscribe(res => {
        resolve(res);
  
        res.map((e: any) => {
          const data = e.payload.doc.data();
          data.id = e.payload.doc.id;
        });
      });
    });
  
    a.then((worktasks) => {
      worktasks.map((tasks) => {
        const data = tasks.payload.doc.data();
        data.id = tasks.payload.doc.id;
  
        if (data.contentType === contentTypeId) {
          this.settingsWorkTaskTemplates.push(data);
        }
      });
    });
  
    return this.settingsWorkTaskTemplates;
  }
  


  observeContextsWorkTasksTemplatesGroupsFirestoreWithId(){
    this.settingsWorkTaskTemplatesGroups = []
    this.settingsWorkTaskTemplatesGroupsSize= 0

    const a = new Promise<any>((resolve)=> {
      const wt= this.firestore
      .collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$).collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS, ref => ref.orderBy("sortIndex", "asc"))
      .snapshotChanges();

      wt.subscribe( res => {
        resolve(res)

        res.map((e:any) => {
          const data = e.payload.doc.data();
          data.id = e.payload.doc.id;
        })
      })
        
    });

    a.then( (worktasks) => {
      worktasks.map( (tasks) => {
        const data = tasks.payload.doc.data();
          data.id = tasks.payload.doc.id;
          this.settingsWorkTaskTemplatesGroups.push(data)
          this.settingsWorkTaskTemplatesGroupsSize++
      })
    })
      return this.settingsWorkTaskTemplatesGroups
  }


 observeFinishedTasks() {
    const a = new Promise<any>((resolve)=> {
      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.contextId$)
      .collection(NetworkConstants.COLLECTION_WORK_TASKS).valueChanges()
      .subscribe(content => {
        resolve(content)});
    })
    this.settingsFinishedTasks = []
    this.settingsFinishedTasks.push(a)

    return a
}

  getContextIdOfUser(id:string){ //this can be don e on getNAmeOfUser--> the firebase call is the same
    const users = this.getDataUsersFirestore()
 
    users.pipe().subscribe(values => {
      values.map( (user:any)=>{
        const data = user.payload.doc.data()
        data.id = user.payload.doc.id;
        
        if(data.id==id){
          this.contextId$ = data.contextId
          this.contextIdChange$.next(this.contextId$)
          if(!data.contextId){
            if(data.contextIds && data.contextIds.length>0){
              this.contextId$ = data.contextIds[0]
              this.contextIdChange$.next(this.contextId$)
            }
          }
          if(data.contextId=="" && data.contextIds.length>1){
            this.contextId$ = data.contextIds[0]
            this.contextIdChange$.next(this.contextId$)
          }
          if(this.preReloadContextId$){
            if(data.contextIds && data.contextIds.includes(this.preReloadContextId$))
              this.contextId$ = this.preReloadContextId$
              this.contextIdChange$.next(this.contextId$)
          }
          return data.contextId;
        }
      })
    });
  }

  observeLoggedData(){
    const au=getAuth()
    this.currentUser$=au.currentUser
    this.uid$=this.currentUser$.uid
    if(!this.currentUser$.displayName){
      this.getNameOfUser(this.currentUser$.uid)
    }else{
      this.userNameChange$.next(this.currentUser$.displayName)
    }

    const contextId =this.getContextIdOfUser(this.currentUser$.uid)
  }
 
  getNameOfUser(uid:string){//uid==uid of signedUser
    const users = this.getDataUsersFirestore()

    users.pipe().subscribe(values => {
      values.map( (user:any)=>{
        const data = user.payload.doc.data()
        data.id = user.payload.doc.id;

        if(data.id==uid){
          this.userAccount$=data
          this.currentUser$.displayName=data.displayName
          this.userNameChange$.next(this.currentUser$.displayName)

          return data.displayName;
        }
      })
    });
  }

  async getLoggedData(){
    this.observeLoggedData()

    const a= this.getDataUsersFirestore()//get User collections data

    a.subscribe( res => {
      res.map((e:any) => {
        const data = e.payload.doc.data();
        data.id = e.payload.doc.id;
        if(data.id == this.currentUser$.uid){//take only the user with the getAuthId
          this.settingsUserData$= data;
          this.contextIds$=deepCopy(data.contextIds)
          this.setUserAccount(this.contextId$)
          this.setContextsAccount(this.contextId$)
          this.observeContextsIdLoggedFirestore(this.contextId$)

          if(data.roles){
            this.userRoles$ = []
            this.userRolesRights$= {}
            if(data.roles.length==1){
              if(!this.userRoles$.includes(data.roles[0]))
                this.userRoles$.push(data.roles[0])
              this.userRolesRights$= this.defaultRoles[data.roles[0]]
            }else{
              if(data.roles.length==0){
                const defaultRoleslist = defaultRoles
                if(!this.userRoles$.includes('default'))
                  this.userRoles$.push('default')
                // this.userRolesRights$ = defaultRolesRights['admin']
                this.userRolesRights$ = userRolesRightsAll
              }else{
                const defaultRoleslist = defaultRoles
                data.roles.forEach( role => {
                  if(!this.userRoles$.includes(role))
                    this.userRoles$.push(role)
                  this.entities.forEach( ent => {
                    if(defaultRoles[role] && defaultRoles[role][ent]){
                      if(this.userRolesRights$[ent]){
                        Object.assign(this.userRolesRights$[ent], defaultRoles[role][ent])
                      }else{
                        this.userRolesRights$[ent] = defaultRoles[role][ent]
                      }
                    }
                  })
                })
              }
            }

            // if(data.roles.includes('betaWidgets')){
            //   this.userRoleBetaWidget = true
            // }else{
            //   this.userRoleBetaWidget = false
            // }
          }else{
            if(!this.userRoles$.includes('default'))
            this.userRoles$.push('default')
            this.userRolesRights$ = userRolesRightsAll
          }
         
          return data;
        }
      })
    })
  }

  observeContextIdFields(contextId?:string, idAmount?:number){
    this.contextIdFields$ = []
    this.SortedcontextIdFields$ = {}
    const a = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
    .doc(contextId)
    .snapshotChanges();

    a.subscribe(res => {
        const data = res.payload.data()
        if(!this.contextIdFields$.includes(data['displayName'])){
           this.allContextsData.push(data)
          this.SortedcontextIdFields$[data['displayName']]  = contextId
          this.contextIdFields$.push(data['displayName'])
        }
      // }
    })
    return this.allContextsData
  }


  observeContextDataOfContexts(contextIds){
    this.allContextsData = []
    this.contextIdFields$ = []
    this.SortedcontextIdFields$ = {}

    contextIds.forEach ( contextId => {
      const a = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(contextId).snapshotChanges();
      a.subscribe(res => {
        const data = res.payload.data()
        data['id'] = res.payload.id

        if(!this.contextIdFields$.includes(data['displayName'])){
          this.allContextsData.push(data)
          this.SortedcontextIdFields$[data['displayName']]  = contextId
          this.contextIdFields$.push(data['displayName'])
        }
      })
    })

    return this.allContextsData
  }

  updateContextId(newcontextId){
    this.contextId$=newcontextId
    this.contextIdChange$.next(this.contextId$)
    this.preReloadContextId$ = newcontextId

    this.resetSettingsValues()
    //this.setUserAccount(newcontextId)
    this.setContextsAccount(newcontextId)
  }

  resetSettingsValues(){
    this.settingsContentTypes = []
    this.settingsFinishedTasks = []
    this.contextModules$ = ""
  }

  resetLogout(){
    this.currentUser$= undefined
    this.allUsers$= undefined
    this.allContextsData= []
    this.settingsUserData$= undefined
    this.contextId$= undefined
    this.contextIdChange$.next(this.contextId$)
    this.contextIds$= []
    this.userId$= []
    this.contextIdFields$= []
    this.SortedcontextIdFields$ = {}
    this.usersWithContextId$= []
    this.changeAccount= false
    this.userAccount$= undefined
    this.contextAccount$= undefined

    this.allContexts$= []
    this.allAnalytics$= []
    this.allTridys$= []
    this.fieldContexts$= []

    this.settingsAccount= undefined
    this.settingsAnalytics= []
    this.settingsAdvancedExperiences= []
    this.settingsAPItokens= []
    this.settingsContent= []
    this.settingsContentTypes= []
    this.settingsContentTypesWithId= []
    this.settingsCustomers= []
    this.settingsFinishedTasks= []
    this.settingsItems= []
    this.settingsIdentifiers= []
    this.settingsMedia= undefined
    this.settingsPassDefinitions= []
    this.settingsPassDefinitionsWithId= []
    this.settingsProducts= undefined
    this.settingsPurchases= []
    this.settingsTaskTemplates= undefined
    this.settingsTridys= undefined
    this.settingsUsers= undefined
    this.settingsWorkTaskTemplates= []
    this.settingsWorkTasks= []
  }

  setUserAccount(contextId:string){
    // const allUsers = this.firestore.collection(NetworkConstants.COLLECTION_USERS).doc(this.uid$).snapshotChanges()
    const allUsers = this.firestore.collection(NetworkConstants.COLLECTION_USERS).snapshotChanges()

    this.userRoles$ = []
    this.userRolesRights$= {}
    allUsers.subscribe( res => {
      res.map((e:any) => {
        const data = e.payload.doc.data();
        data.id = e.payload.doc.id;
          if(data.id == this.uid$){
            this.userAccount$=data
            this.userDataChange$.next(this.userAccount$)

            if(this.userAccount$.displayName){
              this.userNameChange$.next(this.currentUser$.displayName)
            }
            if(data.roles){
              if(data.roles.length==1){
                if(!this.userRoles$.includes(data.roles[0]))
                  this.userRoles$.push(data.roles[0])
                this.userRolesRights$= this.defaultRoles[data.roles[0]]
                if(data.roles[0]=='betaWidgets'){
                  this.userRolesRights$= this.defaultRoles['admin']
                }
              }else{
                if(data.roles.length==0){
                  const defaultRoleslist = defaultRoles
                  if(!this.userRoles$.includes('default'))
                    this.userRoles$.push('default')
                  // this.userRolesRights$ = defaultRolesRights['admin']
                  this.userRolesRights$ = userRolesRightsAll
                }else{
                  const defaultRoleslist = defaultRoles
                  let a = {}
                  data.roles.forEach( role => {
                    if(!this.userRoles$.includes(role))
                      this.userRoles$.push(role)
                    this.entities.forEach( ent => {
                      if(defaultRoles[role] && defaultRoles[role][ent]){
                        if(this.userRolesRights$[ent]){
                          Object.assign(this.userRolesRights$[ent], defaultRoles[role][ent])
                        }else{
                          this.userRolesRights$[ent] = defaultRoles[role][ent]
                        }
                      }else{

                        if(defaultRolesRights[role] && role != "betaWidgets" && defaultRolesRights[role][ent] && !this.userRolesRights$[ent]){
                          a[ent] = defaultRolesRights[role][ent]
                        }
        // this.navbarAccesService.setAccesRoles( this.userRolesRights$)
                        // this.userRolesRights$[ent] = defaultRolesRights[role][ent]
                      }
                    })
                  })
                }
              }
            }else{
              if(!this.userRoles$.includes('default'))
                this.userRoles$.push('default')
              this.userRolesRights$ = userRolesRightsAll
            }

            if(data.roles && data.roles.includes('betaWidgets')){
              this.userRoleBetaWidget = true
            }else{
              this.userRoleBetaWidget = false
            }
          return data;
        }
      })
    })
    return this.userAccount$
  }

  setContextsAccount(contextId:string){
    const allUsers = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(this.contextId$).snapshotChanges()

    allUsers.subscribe( res => {
      const data = res.payload.data()
      data['id'] = res.payload.id
      this.contextAccount$=data
      this.selectedContextDataChange$.next(this.contextAccount$)

      if(this.contextAccount$.modules){
        this.contextModules$ = this.contextAccount$.modules
        if(this.contextAccount$.modules.publisher && !this.contextAccount$.modules.tracer){
          this.navbarAccesService.setAccesModulesPublisher()
        }
        if(!this.contextAccount$.modules.publisher && this.contextAccount$.modules.tracer){
          this.navbarAccesService.setAccesModulesTracer()
        }

        if(!this.contextAccount$.modules.publisher && !this.contextAccount$.modules.tracer){
          this.navbarAccesService.setAccesModulesAll()
        }

        if(this.contextAccount$.modules.publisher && this.contextAccount$.modules.tracer){
          this.navbarAccesService.setAccesModulesPublisherTracer()
        }
      }else{
        this.contextModules$ = ""
        this.navbarAccesService.setAccesRoles( this.userRolesRights$)
      }
    })
    return this.contextAccount$
  }

/*   observeUsersWithcontextId(contextId:string){
    let users =[]
    
    this.usersWithContextId$ = []
    const a = new Promise<any>((resolve)=> {
      const allUsers = this.firestore.collection(NetworkConstants.COLLECTION_USERS).snapshotChanges();
      allUsers.subscribe( res => {
        res.map((e:any) => {
          const data = e.payload.doc.data();
          data.id = e.payload.doc.id;
          const contextIds = data.contextIds
          if(contextIds){
            contextIds.forEach(cid =>{
              if(cid==contextId)
              if(!users.includes(data['displayName'])){
                users.push(data['displayName'])
                this.usersWithContextId$.push(data)
              }
            })
          }
        })
      })
    })
    return this.usersWithContextId$
    
  } */

    observeUsersWithcontextId(contextId: string): Observable<any[]> {
      return new Observable<any[]>(observer => {
        const users = [];
        
        const allUsers = this.firestore.collection(NetworkConstants.COLLECTION_USERS).snapshotChanges();
        allUsers.subscribe(res => {
          res.map((e: any) => {
            const data = e.payload.doc.data();
            data.id = e.payload.doc.id;
            const contextIds = data.contextIds;
            
            if (contextIds) {
              contextIds.forEach(cid => {
                if (cid === contextId && !users.some(u => u.displayName === data.displayName)) {
                  users.push(data);
                }
              });
            }
          });
          
          observer.next(users);
          observer.complete();
        }, err => {
          observer.error(err);
        });
      });
    }
    

  observeData(){
    this.observeContextsContentTypesFirestoreWithId(this.contextId$)
    this.observeContextsPassDefinitionsFirestoreWithId(this.contextId$)
    this.observeUsersWithcontextId(this.contextId$)
  }

  observeReloadLoggedData(route:string){
    if(!this.currentUser$){
      this.commentsService.progressSpin.emit(true)
      this.getLoggedData()
      setTimeout( () => {
        this.observeData()
      }, 300)

      const parts  = route.split("/")
      let newRoute =""
      const item = parts.length-1

      if( parts){
        if(parts[2]){
          newRoute= `${parts[1]}/${parts[2]}`
          this.preReloadContextId$ = parts[2]
        }else
        newRoute= `${parts[1]}`
      }

      if(parts[1].toString() =="home" && parts[2] && parts[3]){
          this.router.navigateByUrl(newRoute)
      }else{
        if(parts[1].toString() =="home" && parts[2] && !parts[3]){
          this.router.navigate(['home', parts[2]])
        }
      }

      setTimeout(() => {
        this.commentsService.progressSpin.emit(false)
      }, 1200);
    }
  }

  checkReloadPAage(route:string){
    const parts  = route.split("/")
    let newRoute =""
    const item = parts.length-1

    if( parts){
      newRoute= `${parts[1]}/${parts[2]}`
    
    if(parts[1].toString() =="home" && parts[2] && parts[3]){
        this.router.navigateByUrl(newRoute)
        this.preReloadContextId$ =  parts[2]
    }else{
      if(parts[1].toString() =="home" && parts[2] && !parts[3]){
        this.router.navigate(['home', parts[2]])
        this.preReloadContextId$ =  parts[2]
      }else{
        if(parts[1]=="selectAccount"){
          if(parts[2]){
            this.preReloadContextId$ =  parts[2]
            this.router.navigate(['home', parts[2]])
          }else{

          }
        }
      }
    }
    }
  }

}
