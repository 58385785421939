import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplatesService } from '../service/templates.service';
import { AngularFireAuth, PERSISTENCE } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { build, version } from 'src/buildVersion';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { InfoMesageDialogComponent } from '../info-mesage-dialog/info-mesage-dialog.component';
@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {
  loginForm!:FormGroup
  logged:any=false
  badLogin=false
  currentUser$:any
  environmentName=environment.firebase
  buildEnvironment:string
  version=version
  languageSelected:any
  languageFlag:any
  buildNumber=build
  hide = true
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  private auth: any;
  errorMessage: string = ''; 


  constructor(private authService: AuthService,
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private templateService: TemplatesService,
    private afauth: AngularFireAuth,
    private commentsService: CommentsService,
    private translate: TranslateService,
    public dialog: MatDialog,
    ){
      const browserLang = this.translate.getBrowserLang();
      this.languageSelected = browserLang
      this.translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
      const currentLang = this.translate.currentLang

      this.languageSelected = currentLang
      if(this.languageSelected=="en"){
        this.languageFlag="gb"
      }else{
        this.languageFlag=this.languageSelected
      }
  }

  ngOnInit():void{
    this.loginForm = this.fb.group({
      email:['',[Validators.required, Validators.email]],
      password:[,[Validators.required]]
    })
    const {redirectURL} = this.route.snapshot.queryParams

    this.loginForm.valueChanges.subscribe( (value:any) => {
      this.badLogin=false
      if (this.loginForm.hasError('invalidCredentials')) {
        const message = this.translate.instant("SnackBarConstants.LOGIN_WRONG_DATA")
        this.commentsService.addSnackBar.emit(message)
        this.loginForm.setErrors({invalidCredentials: false})
      }
    })

    this.authService.user$.subscribe( (user) =>{
      if(user!=null){
        if(redirectURL){
          this.router.navigateByUrl(redirectURL)
        }else{
          this.logged=true
        }
      }else{
      }
    })

    if(this.environmentName.projectId=="narravero-eu-dev"){
      this.buildEnvironment="Narravero DEV "
    }
    if(this.environmentName.projectId=="narravero-eu-prod"){
      this.buildEnvironment="Narravero "
    }
    if(this.environmentName.projectId=="narravero-eu-test"){
      this.buildEnvironment="Narravero TEST "
    }
  }

  logInAction(){
    this.onSubmit()
  }


  closeLanguage(){
    this.trigger.closeMenu();

  }
  onSubmit() {
    this.commentsService.progressSpin.emit(true)
    if(this.loginForm.value.email && this.loginForm.value.password){
      this.auth = getAuth();
      
      this.auth.setPersistence(browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(this.auth, this.loginForm.value.email, this.loginForm.value.password)
        .then(() => {
          this.closeLanguage()
          const logged = this.settingsService.getLoggedData()
          setTimeout( ()=>{

            this.settingsService.preReloadContextId$ = this.settingsService.userAccount$.contextId
            if(!this.settingsService.userAccount$){
              this.commentsService.progressSpin.emit(false)
              const message = this.translate.instant("SnackBarConstants.LOGIN_FAILED")
              this.commentsService.addSnackBar.emit(message)
            }

            if(this.settingsService.userAccount$.contextIds.length>1){
              let contextId 
              if(!this.settingsService.userAccount$.contextId){
                contextId = this.settingsService.userAccount$.contextIds[0]
              }else{
                contextId = this.settingsService.userAccount$.contextId
              }
              this.router.navigate(['selectAccount',this.settingsService.contextId$])
              this.commentsService.progressSpin.emit(false)
              const message = this.translate.instant("SnackBarConstants.LOGIN_OK")
              this.commentsService.addSnackBar.emit(message)

            }else{
              this.settingsService.observeData()
              this.router.navigate(['home', this.settingsService.contextId$])
              const message = this.translate.instant("SnackBarConstants.LOGIN_OK")
              this.commentsService.addSnackBar.emit(message)

            }
          },2000)

        })
        .catch((error) => {
          if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            console.log('Invalid Email or Password');
            const message = this.translate.instant("SnackBarConstants.LOGIN_WRONG_DATA")
            this.commentsService.addSnackBar.emit(message)
            this.errorMessage = 'Invalid Email or Password';
          } else {
            this.commentsService.progressSpin.emit(false)
            const message = this.translate.instant("SnackBarConstants.LOGIN_FAILED")
            this.commentsService.addSnackBar.emit(message)
            console.log(error.message);
          }
        });
      })
      .catch((error) => {
        const message = this.translate.instant("SnackBarConstants.LOGIN_FAILED")
        this.commentsService.addSnackBar.emit(message)
        console.error('Error enabling local persistence:', error);
        this.commentsService.progressSpin.emit(false)
      });
    }else{
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOGIN_WRONG_DATA")
      this.commentsService.addSnackBar.emit(message)
    }

  }

  callForgotPassword(){
    if(this.loginForm.value.email){
      this.commentsService.progressSpin.emit(true)
      this.authService.resetPassword(this.loginForm.value.email).then( 
        () => {
          this.commentsService.progressSpin.emit(false)
          const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_OK")
          this.commentsService.addSnackBar.emit(message)
  
          let dialogRef= this.dialog.open(InfoMesageDialogComponent, {
            data: { 
               values: {
                message: 'PASSWORD_RESET_MAIL_OK',
                mail: this.loginForm.value.email
              }
            },
          });
      
          dialogRef.afterClosed().subscribe(result => {
  
          });
  
        },
        (rejectionReason) => {
          console.log(rejectionReason)
          this.commentsService.progressSpin.emit(false)
  
        }
      ).catch( e => {
        console.log(e)
        const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_FAILED")
        this.commentsService.addSnackBar.emit(message)
        this.commentsService.progressSpin.emit(false)
  
      })
    }else{
      let dialogRef= this.dialog.open(InfoMesageDialogComponent, {
        data: { 
           values: {
            message: 'SET_MAIL_TO_RESET_PASSWORD',
            mail: ''
          }
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }


  useLanguage(language: string): void {
    this.translate.use(language);
    if(language=="en"){
      this.languageFlag="gb"
    }else{
      this.languageFlag=language
    }
}


  getErrorMessage() {
    if (this.loginForm.value.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.loginForm.value.email.hasError('email') ? 'Not a valid email' : '';
  }
}
