import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { removeUndefined } from '../../../../../../backend/utils/object';
import { Template } from '../../../../../../backend/src/template.dto';
import { SettingsService } from 'src/app/service/settings.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';
import { QuillEditorComponent } from 'ngx-quill';
import { MatMenuTrigger } from '@angular/material/menu';
import QuillMarkdown from 'quilljs-markdown';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: ['./text-edit.component.css']
})
export class TextEditComponent implements OnInit {

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild(QuillEditorComponent, { static: true }) editor!: QuillEditorComponent;

  textDataEdit:any
  oldData:Template
  textEditForm!: FormGroup
  url:any
  changes=false
  newImage:any=[]
  languageClass: string = '';
  private langChangeSub: Subscription;
  widgetNumber:any
  passId:any
  files: FileHandle[] = []

  payloadKeys: string[] = [];
  workTaskTemplatesList = []

  drop=false
  passTranslations:any
  translationForm!:FormGroup
  firstLanguageContexts:any
  urlData:any
  passLanguagesList:any
  oldTranslations:any
  addUrl = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  linkUrlTransform:any
  languagesList = languagesList
  defaultLanguage:string

  quillModules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  private md: MarkdownIt;
  private turndownService: TurndownService;
  
  constructor(public dialogRef: MatDialogRef<TextEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private uploadService: UploadService,
              private fb: FormBuilder,
              private imageCompressionService: ImageCompressionService,
              private commentService: CommentsService,
              private settingsService: SettingsService,
              private translate:TranslateService){
                dialogRef.disableClose = true;
                this.md = new MarkdownIt();
                this.turndownService = new TurndownService();
  }
    
  ngOnInit(): void {
    this.defaultLanguage = this.data.defaultLanguage

    this.textDataEdit = this.data.values
    this.oldData= new Template
    this.oldData.setFromAny(this.data)
    this.passId = this.data['passId']
    this.firstLanguageContexts= this.settingsService.firstLanguageContexts$
    if(this.data['url']){
      this.urlData = deepCopy(this.data['url'])
    }else{
      if(this.textDataEdit.image && this.textDataEdit.image.url){        
        this.supportUrl(this.textDataEdit.image.url)
      }
    }

    if(this.data['translations']){
      this.passTranslations = this.data['translations']
      this.oldTranslations = deepCopy(this.data['translations'])
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }
    
    if(this.textDataEdit.image){
      this.url = this.textDataEdit.image.url
    }
    this.widgetNumber= this.data['widget']
    this.passLanguagesList = this.data['languages']
    this.textEditForm = this.fb.group({
      id:[],
      type:[],
      title:[],
      subtitle:[],
      text:[],
      image:[],
      imageUrl:[],
      imageLinkURL:[ ,Validators.pattern(this.urlRegex)],
      // imageDescription:[],
      imagePosition:[],
      name:[],
      textAlignment:[]
    })
    const {id, type, title, subtitle, text, image, imagePosition, name, textAlignment} = this.textDataEdit

    this.textEditForm.patchValue({
      id: id? id : undefined,
      type: type ? type :undefined,
      title: title ? title : undefined,
      subtitle: subtitle ? subtitle : undefined,
      text: text ? text : undefined,
      image: image ? image : undefined,
      imageUrl: image?.url ? image?.url : undefined,
      imageLinkURL: image?.linkURL ? image?.linkURL : undefined,
      // imageDescription: image.description ? image.description : undefined,
      imagePosition: imagePosition ? imagePosition : undefined,
      name: name ? name : undefined,
      textAlignment: textAlignment ? textAlignment : undefined
    })

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })
    this.translationForm.valueChanges.subscribe(value => {
      if(value.imageUrl){
        if(this.textEditForm.value.textAlignment && this.textEditForm.value.textAlignment=='center')
          this.textEditForm.patchValue({textAlignment : 'left'})
      }
    })

    this.textEditForm.get('image').valueChanges.subscribe( (value:any) =>{
      if(value && value.url){
        this.supportUrl(value.image.url)
        if(this.textEditForm.value.textAlignment && this.textEditForm.value.textAlignment=='center')
          this.textEditForm.patchValue({textAlignment : 'left'})
      }
    })

    this.textEditForm.get('imageLinkURL').valueChanges.subscribe( (value:any) =>{
      this.checkLinkUrlPattern()
    })

    this.textEditForm.get('imageUrl').valueChanges.subscribe( (value:any) =>{
      if(this.addUrl){
        this.url = value
        this.supportUrl(value)
      }

      if(value ){
        if(this.textEditForm.value.textAlignment && this.textEditForm.value.textAlignment=='center')
          this.textEditForm.patchValue({textAlignment : 'left'})
      }
    })
    
    // check for payload keys and add them
    this.fetchAndInspect()

    // Load existing markdown
    if (this.data && this.data.text) {
      this.textEditForm.patchValue({ text: this.data.text });
      this.loadContent(this.data.text); // Load markdown into editor
    }

    // Set initial class based on current language
    this.languageClass = this.getLanguageClass();
    // Subscribe to language change events
    this.langChangeSub = this.translate.onLangChange.subscribe(() => {
      this.languageClass = this.getLanguageClass();
    });
  }

  ngOnDestroy() {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  getLanguageClass(): string {
    const currentLang = this.translate.currentLang || this.translate.defaultLang;
    return `lang-${currentLang}`;
  }
  
  // START WYSWIG EDITOR

  private quillEditor: any;
  onEditorCreated(quill: any) {
    this.quillEditor = quill;
    new QuillMarkdown(quill);

    // Load any initial markdown content
    if (this.textEditForm.value.text) {
      this.markdownToQuill(this.textEditForm.value.text);
    }

    // clipboard handling
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: any, delta: any) => {
      return delta; // Returning the delta unmodified
    });

  }
  private isInsertingContent: boolean = false;
  private isProcessingMarkdown: boolean = false;
  
  markdownToQuill(markdown: string) {
    if (this.editor && this.editor.quillEditor) {
      if (this.isInsertingContent) {
        return; // Prevent re-entrance
      }
      this.isInsertingContent = true;
      const html = this.md.render(markdown); // Markdown to HTML
      this.editor.quillEditor.clipboard.dangerouslyPasteHTML(html); // HTML into Quill
      this.isInsertingContent = false;
    }
  }
  
  saveMarkdown() {
    if (this.editor && this.editor.quillEditor) {
        const html = this.editor.quillEditor.root.innerHTML; // Get HTML
        const markdownContent = this.turndownService.turndown(html); // HTML to Markdown
        //console.log('Markdown content:', markdownContent);
        return markdownContent;
    }
    return '';
  }

  loadContent(markdown: string) {
    if (this.editor && this.editor.quillEditor) {
      const html = this.md.render(markdown); // markdown to HTML
      this.editor.quillEditor.clipboard.dangerouslyPasteHTML(html); // Insert HTML
    }
  }

  addPayloadKeyAtCursor(payloadKey: string) {
    const quillEditor = this.editor.quillEditor;
    const range = quillEditor.getSelection(true);
    if (range) {
      const formattedPayloadKey = `\${${payloadKey}}`;
      setTimeout(() => {
        quillEditor.deleteText(range.index, range.length); 
        quillEditor.insertText(range.index, formattedPayloadKey);
      }, 0);
    }
  }

  fetchAndInspect() {
    this.getWorkTasksList();
    setTimeout(() => {
      this.inspectWorkTaskTemplatesList();
    }, 500);
  }

  inspectWorkTaskTemplatesList() {
    if (Array.isArray(this.workTaskTemplatesList)) {
      this.payloadKeys = [];  // Clear previous keys

      this.workTaskTemplatesList.forEach((workTask) => {
        workTask.formSections?.forEach((section) => {
          section.fields?.forEach((field) => {
            if (field.payloadKey) {
              this.payloadKeys.push(field.payloadKey);
            }
          });
        });
      });
    } else {
      console.log('Work Task Templates List is empty');
    }
  }
  
   getWorkTasksList() {
    const contentTypeId = this.data['contentTypeId']
    if(this.settingsService.settingsWorkTaskTemplates){
      this.workTaskTemplatesList = this.settingsService.settingsWorkTaskTemplates
    }
    try{
      this.commentService.progressSpin.emit(true)
      this.workTaskTemplatesList = this.settingsService.observeContextsWorkTasksTemplatesFirestoreWithContentTypeId(contentTypeId)
      this.commentService.progressSpin.emit(false)

    }catch(error){
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }
  
  // END WYSWIG EDITOR

  async readURL(event: any): Promise<void> {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload

      // Check for supported file types
      const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!supportedTypes.includes(file.type)) {
        // Unsupported file type
        const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }

      let imageToUpload = file;
  
      if (file.size > maxSizeInBytes) {
        try {
          const compressedImage = await this.imageCompressionService.compressImage(file, maxSizeInBytes);
          if (!compressedImage) {
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          imageToUpload = compressedImage;
        } catch (error) {
          console.log(error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
          return;
        }
      }
  
      const reader = new FileReader();
      this.newImage = imageToUpload;
      reader.readAsDataURL(imageToUpload);
  
      reader.onload = async (event) => {
        if (event.target) {
          const urlNew = event.target.result as string;
          if (urlNew) {
            this.changes = true;
  
            try {
              const uploadURL = await this.uploadService.uploadNewTextImage(this.passId, this.newImage, this.widgetNumber);
  
              if (uploadURL) {
                this.url = uploadURL;
                this.urlData = urlNew;
                this.textEditForm.patchValue( {imageUrl : this.url })
                if (this.textEditForm.value.textAlignment && this.textEditForm.value.textAlignment == 'center')
                  this.textEditForm.patchValue({ textAlignment: 'left' });
              }
            } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }
  }

  async filesDropped(files: FileHandle[]): Promise<void> {

    this.files = files;
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    if (fileToUpload.size > maxAllowedSizeInBytes) {
      // File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    if (fileToUpload.size > maxSizeInBytes) {
      // File is between 2 MB and 20 MB, attempt compression
      try {
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        this.newImage = compressedImage;
      } catch (error) {
        console.error("Compression error:", error);
        const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    } else {
      // File is less than or equal to 2 MB, no compression needed
      this.newImage = fileToUpload;
    }
    const newUrl = files[0].url;
  
    if (fileToUpload.type == 'image/jpeg' || fileToUpload.type == 'image/png' || fileToUpload.type == 'image/webp') {
      if (newUrl) {
        this.drop = true;
        this.changes = true;
  
        try {
          const uploadURL = await this.uploadService.uploadNewTextImage(this.passId, this.newImage, this.widgetNumber);
  
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = newUrl;
            this.textEditForm.patchValue( {imageUrl : this.url })
  
            if (this.textEditForm.value.textAlignment && this.textEditForm.value.textAlignment == 'center')
              this.textEditForm.patchValue({ textAlignment: 'left' });
          }
        } catch (error) {
          console.error(error);
          const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
          this.commentService.addSnackBar.emit(message);
        }
      }
    } else {
      const message = this.translate.instant('SnackBarConstants.UNSUPPORTED_IMG_TYPE');
      this.commentService.addSnackBar.emit(message);
    }
  }

  checkNewUrl(){
    this.url = this.textEditForm.value.imageUrl
    this.supportUrl(this.textEditForm.value.imageUrl)
  }
  
  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  deleteImg(x:any){
    this.url=undefined
    this.textEditForm.patchValue({ image:undefined, imageUrl: undefined, imageLinkURL: undefined })
    this.urlData=""
  }

  editImg(url){
    const data = { 
      type: this.textEditForm.value.type,

    }
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: this.textEditForm.value,
        type: this.textEditForm.value.type,
        passId: this.passId,
        widgetNumber: this.widgetNumber

      },
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if(result[1]){
        const data = removeUndefined(result[0])
        const {url , linkURL} = data
        if(url!=this.textEditForm.value.url){
          this.urlData=""
        }
        const image = {
          url , linkURL
        }
        if(linkURL){
          this.textEditForm.patchValue({image})
        }else{
          if(url)
            this.textEditForm.patchValue({'image': {url}})
          else
            this.textEditForm.patchValue({'image': {}})
        }
        if(url)
          this.url=url

        if(this.textEditForm.value.image && this.textEditForm.value.image.url){
          this.supportUrl(this.textEditForm.value.image.url)
          if(this.textEditForm.value.textAlignment && this.textEditForm.value.textAlignment=='center')
            this.textEditForm.patchValue({textAlignment : 'left'})
        }
        
      }
    });
  }

  onCancelEdit(){
    this.passTranslations = this.oldData.translations
    this.dialogRef.close([this.oldData, false])
  }

  async onSaveEdit() {
    this.commentService.progressSpin.emit(true);
  
    this.textEditForm.get('text')?.setValue(this.editor.quillEditor.root.innerHTML);
  
    const markdownContent = this.saveMarkdown();
    this.textEditForm.get('text')?.setValue(markdownContent);
    const data = this.getWidgetFormatedData(this.textEditForm.value);
  
    this.commentService.progressSpin.emit(false);
    this.dialogRef.close([data, true, this.newImage, this.translationForm.value.translations]);
  }
  
  

  onLanguageEditor(){
    let alltranslationsWidget = {}
    let translationsWidget
    
    if(this.translationForm.value.translations){
        translationsWidget = Object.keys(this.translationForm.value.translations) 
      }else{
        translationsWidget = []
      }

    if(this.passLanguagesList){
      translationsWidget=this.passLanguagesList
    }

    if(translationsWidget.length!=0){
      translationsWidget.forEach(lang => {
        if(this.translationForm.value.translations){
          if(this.translationForm.value.translations[lang]){
            let entries = Object.entries(this.translationForm.value.translations[lang]);
            entries.forEach( ent => {
              if(ent[0].includes(this.textEditForm.value.id)){
                const key = lang+ "." + ent[0]
                alltranslationsWidget[key] = ent[1]
              }
            })
          }
        }
     })
    }

    let dialogRef = this.dialog.open(LanguageEditorDialogComponent, {
      data:{
        translations: this.translationForm.value.translations,
        fields: this.textDataEdit,
        columns: ['title', 'subtitle','text'],
        values: this.textEditForm.value,
        type: "text",
        valuesTranslated: alltranslationsWidget,
        languages: translationsWidget
      },
    });
    let newtranslations = {}

    if(this.translationForm.value.translations){
      newtranslations = this.translationForm.value.translations
    }
    
    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        const data = result[0]
        removeUndefined(data)
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2]
          const tranlationField = id +"."+field

          //------------------------widgetData on first Language translations
          // if(lang == this.firstLanguageContexts){
          //   if(this.textEditForm.value[field]){
          //     this.textEditForm.patchValue({[field]: value[1]})
          //   }
          // }
          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

              //to delete old value
              if(this.oldTranslations[lang] && this.oldTranslations[lang][tranlationField])
                newtranslations[lang][tranlationField] = ''

            }else{
              newtranslations[lang] = {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
        })
        this.translationForm.patchValue({translations: newtranslations})
      }
      
      
    })
  }

  readDocument(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB size limit
      
      // Define allowed file types
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/webp',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'text/csv',
        'text/vcard',
        'application/rtf'
      ];
  
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
        this.commentService.addSnackBar.emit(message);
        return;
      }
      
      // Validate file size
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
      
      // Proceed with reading and uploading the file
      const reader = new FileReader();
      this.newImage = file;
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        if (e.target) {
          const urlNew = e.target.result as string;
  
          try {
            const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newImage, this.widgetNumber);
            if (uploadURL) {
              this.textEditForm.get("imageLinkURL")?.setValidators([Validators.pattern(this.urlRegexDoc)]);
              this.linkUrlTransform = uploadURL;
              this.textEditForm.patchValue({ imageLinkURL: this.linkUrlTransform });
            }
          } catch (error) {
            console.error(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      };
    }
  }  

  supportUrl(url){
    this.urlData = url
    if(url){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName

        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.urlData=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.urlData=firebaseUrl
            }
          }
        }

        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.urlData=firebaseUrl
        }

        if(imgUrl.protocol == "context:"){
          const parts = url.match(/context:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
              if(index>0 && index<parts.length){
                  path += "/" + parte
              }
          })
          const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
          this.urlData=firebaseUrl
        }
      }catch(error){}
    }
  }

  getWidgetFormatedData(data){
    let {id, type, title, subtitle, text, imageUrl, imageLinkURL, imagePosition, name, textAlignment} = data

    let image = undefined

    if(imageLinkURL == 'https://')
      imageLinkURL = undefined
  
    if( imageUrl && imageLinkURL){
      image = { url: imageUrl,  linkURL: imageLinkURL}
    }else{
      if( imageUrl )
        image = { url: imageUrl}

      if( imageLinkURL )
      image = { linkURL: imageLinkURL}
        // image['linkURL'] = imageLinkURL
    }
    
    return {
      id: id ? id : undefined,
      type: type ? type :undefined,
      title: title ? title : undefined,
      subtitle: subtitle ? subtitle : undefined,
      text: text ? text : undefined,
      image: image ? image : undefined,
      imagePosition: imagePosition ? imagePosition : undefined,
      name: name ? name : undefined,
      textAlignment: textAlignment ? textAlignment : undefined
    }
  }

  checkLinkUrlPattern(){
    const linkUrl = this.textEditForm.value.imageLinkURL
    if(linkUrl){
      try{
        const imgUrl = new URL(linkUrl)
  
        if(imgUrl.protocol=="pass:" )
          this.textEditForm.get("imageLinkURL").setValidators([Validators.pattern(this.urlRegexDoc)]);

        if(imgUrl.protocol=="https:" )
          this.textEditForm.get("imageLinkURL").setValidators([Validators.pattern(this.urlRegex)]);

      }catch(e){}
    }

  }
}
